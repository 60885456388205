//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginForm from './loginForm';
import RegistrationForm from './registrationForm';
import RestorePasswordForm from './restorePasswordForm';

export default {
  name: 'AuthDialog',
  components: {
    LoginForm,
    RegistrationForm,
    RestorePasswordForm,
  },
  props: ['value'],
  data() {
    return {
      nameDisplayedComponent: 'login',
    };
  },
  computed: {
    localModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.localModel = false;
    },
  },
};
