export default function () {
  // Некоторые методы, такие как вызов диалоговых окон, могут добавлять
  // к html класс overflow-y-hidden, который блокирует скролл для всей страницы.
  // Без глобального middleware блокирующий класс может не удаляться
  // при неожиданном закрытии диалогового окна (например - уход со страницы с диалогом)
  if (process.client) {
    const html = document.documentElement;
    html.classList.remove('overflow-y-hidden');
  }
}
