/**
 * Костыль, который нужен для того, чтобы получить доступ к стору в router.js
 * В текущей версии nuxt такого сделать нельзя
 * */

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    list: [],
    store: null,
  }),
  mutations: {
    setDirections(state, directions) {
      state.list = directions;
    },
    setStore(state, store) {
      state.store = store;
    },
  },
  actions: {
    async getDirections(/*{ commit }, */ params) {
      await params.store.dispatch('directions/getDirections');
    },
    getStore({ commit }, params) {
      const store = params.store;
      commit('setStore', store);
      return store;
    },
  },
});
