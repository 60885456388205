export default {
  mounted() {
    const gclid = this.getLocationParam('gclid');
    if (gclid) {
      this.setCookie('gclid', gclid, 365);
    }
    const fbclid = this.getLocationParam('fbclid');
    if (fbclid) {
      const epoch = new Date().valueOf();
      this.setCookie('fbclid', `fb.1.${epoch}.${fbclid}`, 365);
    }
  },
  methods: {
    setCookie(name, value, days) {
      let expires = '';
      if (days && parseInt(days)) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toGMTString();
      }

      document.cookie = name + '=' + value + expires + ';path=/';
    },
    getLocationParam(p) {
      const match = RegExp('[?&]' + p + '=([^&]*)').exec(
        window.location.search,
      );
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    },
  },
};
