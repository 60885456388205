import getCookie from '../utils/getCookie';
import getJsonFromUrl from '../utils/getJsonFromUrl';
import getGaCid from '~/utils/getGaCid';
import getUserIp from '~/utils/getUserIp';

export default async function addAnyFormFields({ ref }) {
  this.debug({ name: 'addAnyFormFields' }, 'ref = %O', ref);
  // Если по каким-то причинам не подгрузилась форма, выходим. Ретраить или ждать смысла нет - это нужно делать на уровне выше
  if (!ref?.$el) {
    this.debug({ name: 'addAnyFormFields' }, 'ref.$el is null');
    return;
  }

  if (!window.gaData) {
    await new Promise((resolve) => setTimeout(resolve, 2000));
  }

  const formSelector = ref.$el.querySelector('form');

  const userIp = await getUserIp.call(this);
  if (userIp) {
    formSelector.insertAdjacentHTML(
      'beforeend',
      '<input type="hidden" name="ClientIP" value="ip=' + userIp + '">',
    );
  }

  let currentUrl = window.location.toString(),
    currentParams = getJsonFromUrl(currentUrl),
    hiddenFields = [];

  try {
    if (!(currentParams && currentParams['gclid'])) {
      const gclid = getCookie({ name: 'gclid', cookies: document.cookie });
      if (gclid) currentParams['gclid'] = gclid;
    }

    if (!(currentParams && currentParams['fbclid'])) {
      const fbclid = getCookie({ name: 'fbclid', cookies: document.cookie });
      if (fbclid) currentParams['fbclid'] = fbclid;
    } else {
      // TODO: fix
      // Временное решение - все равно получаем данные из куки, даже если они есть в currentParams
      const fbclid = getCookie({ name: 'fbclid', cookies: document.cookie });
      if (fbclid) currentParams['fbclid'] = fbclid;
    }

    const _fbp = getCookie({ name: '_fbp', cookies: document.cookie });
    if (_fbp) currentParams['_fbp'] = _fbp;

    formSelector.insertAdjacentHTML(
      'beforeend',
      '<input type="hidden" name="window_height" value="' +
        window.innerHeight +
        '">',
    );
    formSelector.insertAdjacentHTML(
      'beforeend',
      '<input type="hidden" name="window_width" value="' +
        window.innerWidth +
        '">',
    );
    formSelector.insertAdjacentHTML(
      'beforeend',
      '<input type="hidden" name="Referrer" value="' + document.referrer + '">',
    );
    formSelector.insertAdjacentHTML(
      'beforeend',
      '<input type="hidden" name="PageUrl" value="' +
        window.location.toString() +
        '">',
    );
    formSelector.insertAdjacentHTML(
      'beforeend',
      '<input type="hidden" name="UserTimestamp" value="' + new Date() + '">',
    );
    if (this) {
      formSelector.insertAdjacentHTML(
        'beforeend',
        '<input type="hidden" name="UserDate" value="' +
          (new Date().toLocaleDateString(this.$app.config.current.localeName) +
            ' ' +
            new Date().toLocaleTimeString(
              this.$app.config.current.localeName,
            )) +
          '">',
      );
    }
    formSelector.insertAdjacentHTML(
      'beforeend',
      '<input type="hidden" name="UserAgent" value="' +
        window.navigator.userAgent +
        '">',
    );

    const gaCid = getGaCid();

    if (gaCid) {
      formSelector.insertAdjacentHTML(
        'beforeend',
        '<input type="hidden" name="ga_cid" value="' + gaCid + '">',
      );
    }

    if (window.gaData?.['UA-165354511-2']?.experiments) {
      formSelector.insertAdjacentHTML(
        'beforeend',
        '<input class="ga_experiments" type="hidden" name="experiments" value="">',
      );
      const gaExperimentsNode = document.querySelector('.ga_experiments');
      if (gaExperimentsNode && gaExperimentsNode.value) {
        gaExperimentsNode.value = JSON.stringify(
          window.gaData['UA-165354511-2'].experiments,
        );
      }
    }
  } catch (e) {
    console.error(e);
  }

  if (this?.$app?.sbjs?.get?.current?.typ) {
    if (this.$app.sbjs.get.current.src) {
      if (this.$app.sbjs.get.current.src === '(none)') {
        currentParams['utm_source'] = 'not_set';
      } else {
        currentParams['utm_source'] = this.$app.sbjs.get.current.src;
      }
    } else {
      currentParams['utm_source'] = 'not_set';
    }

    if (this.$app.sbjs.get.current.mdm) {
      if (this.$app.sbjs.get.current.mdm === '(none)') {
        currentParams['utm_medium'] = 'not_set';
      } else {
        currentParams['utm_medium'] = this.$app.sbjs.get.current.mdm;
      }
    } else {
      currentParams['utm_medium'] = 'not_set';
    }

    if (this.$app.sbjs.get.current.cmp) {
      if (this.$app.sbjs.get.current.cmp === '(none)') {
        currentParams['utm_campaign'] = 'not_set';
      } else {
        currentParams['utm_campaign'] = this.$app.sbjs.get.current.cmp;
      }
    } else {
      currentParams['utm_campaign'] = 'not_set';
    }

    if (this.$app.sbjs.get.current.cnt) {
      if (this.$app.sbjs.get.current.cnt === '(none)') {
        currentParams['utm_content'] = 'not_set';
      } else {
        currentParams['utm_content'] = this.$app.sbjs.get.current.cnt;
      }
    } else {
      currentParams['utm_content'] = 'not_set';
    }

    if (this.$app.sbjs.get.current.trm) {
      if (this.$app.sbjs.get.current.trm === '(none)') {
        currentParams['utm_term'] = 'not_set';
      } else {
        currentParams['utm_term'] = this.$app.sbjs.get.current.trm;
      }
    } else {
      currentParams['utm_term'] = 'not_set';
    }

    if (this.$app.sbjs.get.session.cpg) {
      if (this.$app.sbjs.get.session.cpg === '(none)') {
        currentParams['current_page'] = 'not_set';
      } else {
        currentParams['current_page'] = this.$app.sbjs.get.session.cpg;
      }
    } else {
      currentParams['current_page'] = 'not_set';
    }

    if (this.$app.sbjs.get.current_add.rf) {
      if (this.$app.sbjs.get.current_add.rf === '(none)') {
        currentParams['referrer_url'] = 'not_set';
      } else {
        currentParams['referrer_url'] = this.$app.sbjs.get.current_add.rf;
      }
    } else {
      currentParams['referrer_url'] = 'not_set';
    }

    if (this.$app.sbjs.get.current_add.ep) {
      if (this.$app.sbjs.get.current_add.ep === '(none)') {
        currentParams['entrance_point'] = 'not_set';
      } else {
        currentParams['entrance_point'] = this.$app.sbjs.get.current_add.ep;
      }
    } else {
      currentParams['entrance_point'] = 'not_set';
    }
    if (this.$app.sbjs.get.udata.vst) {
      if (this.$app.sbjs.get.udata.vst === '(none)') {
        currentParams['visits_count'] = 'not_set';
      } else {
        currentParams['visits_count'] = this.$app.sbjs.get.udata.vst;
      }
    } else {
      currentParams['visits_count'] = 'not_set';
    }
  }

  if (currentParams && Object.keys(currentParams).length > 0) {
    Object.keys(currentParams).forEach((key) => {
      hiddenFields[key] = currentParams[key];
      formSelector.insertAdjacentHTML(
        'beforeend',
        '<input type="hidden" name="' +
          key +
          '" value="' +
          currentParams[key] +
          '">',
      );
    });
  }
}
