//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { nanoid } from 'nanoid';

export default {
  name: 'Checkbox',
  props: ['value', 'errorMessages', 'black', 'white', 'theme'],
  data() {
    return {
      isHover: false,
    };
  },
  computed: {
    isError() {
      const error = this.error;

      return !!error;
    },
    error() {
      const errors = this.errorMessages || [];

      return errors?.[0];
    },
  },
  created() {
    this.id = nanoid();
  },
  methods: {
    toggle(e) {
      if (
        e.target.nodeName.toLowerCase() !== 'a' &&
        e.target.nodeName.toLowerCase() !== 'label'
      ) {
        this.$emit('input', !this.value);
      }
    },
    mouseOver(e) {
      if (e.target.nodeName.toLowerCase() !== 'a') this.isHover = true;
      else this.isHover = false;
    },
    mouseLeave(e) {
      if (e.target.nodeName.toLowerCase() !== 'a') this.isHover = false;
    },
  },
};
