//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import parseYoutubeLinkToId from '../utils/parseYoutubeLinkToId';

export default {
  components: {
    ContentList: () => import('./common/contentList.vue'),
  },
  props: {
    dialogClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      videoDisplay: false,
      videoLink: null,
      items: [],
    };
  },
  async fetch() {
    let responseReviews;
    try {
      responseReviews = await this.$axios({
        method: 'GET',
        url: `${this.$nuxt.$config.CMS_REST_API}/public/v2/testimonials/`,
        // url: `https://cms.ebaconline.com.br/api/public/v2/testimonials/`,
        params: {
          limit: 20,
          offset: 0,
          showOnMain: true,
        },
      });
    } catch (e) {
      console.error(e);
      // error({ statusCode: 500, message: 'Os trabalhos técnicos estão em andamento' });
      // throw e;
    }

    if (responseReviews) {
      if (responseReviews.data.error) {
        throw new Error(
          `An error occurred while executing the query (${responseReviews.data.error})`,
        );
      }
      this.items = this.shuffle(responseReviews.data.data.data);
    }

    // this.$store.commit('mainPage/setReviews', Object.assign([], reviews));
  },
  methods: {
    openDialog(link) {
      this.debug({ name: 'openDialog' }, 'link = %s', link);

      // this.videoLink = null
      if (link) {
        this.videoDisplay = true;
        this.videoLink = parseYoutubeLinkToId(link);
      }
    },
    closeDialog() {
      this.debug({ name: 'closeDialog' });

      this.videoDisplay = false;
      this.clearLink();
    },
    clearLink() {
      this.debug({ name: 'clearLink' });

      this.videoLink = null;
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
  },
};
