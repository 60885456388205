import message from '../../i18n/es-mx';

// Аргентина
export default {
  // Плашка распродажи
  saleBanner: {
    endDate: '',
    eternal: false, // вечная распродажа? если да, то endDate можно не заполнять (если ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, ПН, то до ПН)
    eternalV2: false, // вечная распродажа? если да, то endDate можно не заполнять (если ПН, ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, то до ВС)
    name: '100822', // название распродажи, формат ddmmyy
    link: 'https://ebaconline.com.br/sale/junho-da-virada', // ссылка на распродажу
  },

  // Slug распродажи, которую нужно отобразить в новом дизайне
  saleNewDesignSlug: [],

  saleReferral: [],

  // Поведение корзины на странице распродажи
  // Ключ - slug, value - опция
  // 1 - дефолт
  // 2 - дефолт + при добавлении в корзину, popup корзины сразу же будет появляться
  // 3 - как таковой корзины нет, при добавлении в корзину будет появляться popup корзины,
  // но в popup`e будут только форма, списка продуктов не будет. После закрытия popup`а,
  // товар будет удаляться из корзины. Плавающая иконка корзины пропадает
  saleBasketType: {},

  // Slug распродажи, на котором будет отображено колесо фортуны
  fortuneWheelSlug: [],

  // Редиректы на платформу автовебинаров
  autoWebinarsRedirects: [],

  // Локализация
  localeName: 'es-mx',
  utcOffset: -3,
  message,
  numberFormat: {
    currency: {
      style: 'currency',
      currency: 'ARS',
      currencyDisplay: 'symbol',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'ARS',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
    },
  },
  defaultPhoneMask: 'ar',

  newDomainName: 'https://ebac.ar', // домен, который будет заменять домен у некоторых ссылок, приходящих из cms. 1g5jk12

  lmsUrl: 'https://lms.ebac.ar/',
  lmsLoginUrl: 'https://lms.ebac.ar/login/',

  // Webinars
  withWebinars: false,
  webinarsUrl: '',
  // Blog
  withBlog: true,
  tildaBlog: false,
  blogUrl: 'https://ebac.ar/blog',
  getBlogsUrl: 'https://ebac.ar/api/tilda-main-blog',
  blogLinks: [
    {
      href: 'https://ebac.ar/blog#!/tab/332850082-2',
      key: 'template.design',
    },
    {
      href: 'https://ebac.ar/blog#!/tab/332850082-3',
      key: 'template.gaming',
    },
  ],

  withNewCoursesSection: false,

  feedbackUrl: 'https://gdd.ebaconline.com.br/exec',
  feedbackContentType: 'application/x-www-form-urlencoded',
  leadFormUrl: 'https://gdd.ebaconline.com.br/exec',
  saleUrl: 'https://gdd.ebaconline.com.br/exec',
  promocodeUrl: 'https://cms.ebac.mx/api/shopping-cart',
  webinarsCreateLeadUrl: 'https://gdd.ebaconline.com.br/exec',
  webinarsCreateLeadContentType: 'application/x-www-form-urlencoded',
  getPaymentLink: '',
  checkSubscriptionsLink: 'https://api.lms.ebac.ar/subscriptions/is-mine',
  webinarVisitorEventsUrl:
    'https://wisggajuuczifmvdtlzwluwpei0urtta.lambda-url.sa-east-1.on.aws/webinar-visitors-webhook',

  // Source id
  sourceId: {
    index: null,
    sale: null,
    saleBasket: null,
  },

  //b2b landing link
  b2bLink: '',
  b2bLinks: {
    corporateEducation: { url: '' },
    challenges: { url: '' },
  },

  // Documents
  documents: {
    privacyNoticeStudents:
      'https://ebac.mx/upload/cms/ebac_aviso_de_privacidad_integral_alumnos.pdf',
    privacyNoticeTeachers:
      'https://ebac.mx/upload/cms/ebac_aviso_de_privacidad_integral_profesores.pdf',
    termsAndConditions:
      'https://ebac.mx/upload/cms/ebac_tyc_alumnos_pag_cursos.pdf',
  },
  footerDocuments: [
    'privacyNoticeStudents',
    'privacyNoticeTeachers',
    'termsAndConditions',
  ],
  formDocument: 'privacyNoticeStudents', // documents.privacyNoticeStudents
  formWithCheckbox: false,

  // Social
  phone: '',
  phoneLink: '',
  whatsApp: '',
  whatsAppLink: '',
  email: '',
  emailLink: '',
  instagramLink: 'https://www.instagram.com/ebac_latam/?hl=es',
  facebookLink: 'https://www.facebook.com/EbacLATAM',
  youtubeLink: 'https://www.youtube.com/channel/UCVqYXk6kbrdcDbmPtOw9v4g',
  linkedInLink: '',
  tiktok: '',

  // Vacancies
  vacancies: {
    locations: [
      {
        slug: 'sao-paulo',
        value: 'São Paulo',
        label: 'São Paulo',
      },
      {
        slug: 'remote',
        value: 'Remote',
        label: 'Remote',
      },
    ],
  },
  externalVacanciesLink: 'https://ebac_latam.gupy.io/',

  // Global head
  head: {
    lang: 'es',
    meta: [
      {
        name: 'facebook-domain-verification',
        content: 'bm81ix33ewin04r43wvttup6uvf09j',
      },
    ],
    link: [],
    script: [
      // {
      //   innerHTML: `(function(a,m,o,c,r,m){a[m]={id:"55739",hash:"1ff7300d2677990955830635bd87352e4d8646284065bebd0d2b4738baf4a888",locale:"en",inline:true,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.com/js/button.js?1630666958';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));`,
      //   type: 'text/javascript',
      //   async: true,
      // },
    ],
    __dangerouslyDisableSanitizers: ['script'],
  },

  bodyScripts: [],

  // career-center-students script
  careerCenterStudentsScripts: [],

  gtm: {
    debug: false,
    id: 'GTM-PCTL3KZ',
    autoInit: true,
    enabled: true,
    pageTracking: true,
    scriptDefer: true,
  },

  //hreflang linking SEO
  href: 'https://ebac.ar',

  links404: [
    {
      key: 'courses',
      link: '/cursos?types=course',
    },
    {
      key: 'professions',
      link: '/cursos?types=profession',
    },
    {
      key: 'blogArticles',
      link: 'https://ebac.ar/blog',
    },
    {
      key: 'aboutUs',
      link: '/about-us',
    },
  ],

  lmsRestApiUrl: 'https://api.lms.ebac.ar',

  domain: 'ebac.ar',

  autoWebinarLoginLinkAws:
    'https://external-api.ebac.app/axl-autowebinar-subscription-processing',

  jobGuarantee: false,
};
