/**
 * TODO: переделать
 * сейчас миксин подключается к компонентам индивидуально,
 * соответственно в каждой компоненте дублируется расчет,
 * это неправильно, нужно сделать глобальный расчет
 */
export default {
  data() {
    return {
      isMobile: false,
      isTablet: false,
      currentScreenWidth: 0,
    };
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },

  methods: {
    checkScreenSize() {
      this.currentScreenWidth = window?.innerWidth || 0;
      // this.debug(
      //   { name: 'checkScreenSize' },
      //   `currentScreenWidth = %d`,
      //   this.currentScreenWidth,
      // );

      this.isMobile = window && window.innerWidth <= 599;
      this.isTablet = window && window.innerWidth <= 1024;

      // this.debug({ name: 'checkScreenSize' }, `isMobile = %O`, this.isMobile);
      // this.debug({ name: 'checkScreenSize' }, `isTablet = %O`, this.isTablet);
    },
  },
};
