//
//
//
//
//
//

import isMobile from '../mixins/isMobile.mixin';
import setDefaultCookie from '../mixins/setDefaultCookie.mixin';

export default {
  mixins: [isMobile, setDefaultCookie],
};
