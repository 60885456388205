import config from '../config/countries';

export default function ({ store, route, $config, $app }) {
  //exclude dynamic routes
  if (Object.keys(route.params).length > 0) return;

  let show = route.meta[0].show || [];
  if (show.length === 1) return;

  if (show.length === 0) show = $app._data.config.common.codes;

  const link = [];
  for (const country of show) {
    if (country === $config.COUNTRY) continue;

    const conf = config(country);
    const hreflang = conf.current.hreflang;
    if (!hreflang) continue;

    link.push({
      hid: `alternate${country}`,
      rel: 'alternate',
      hreflang,
      href: conf.current.href + route.path,
    });
  }

  store.commit('seo/setLink', link);
}
