import Vue from 'vue';
// Стор для детальной страницы ивентов

import getCookie from '@/utils/getCookie';

export const state = () => ({
  // ! Время для таймера и определения статусов
  // Время diffTopicLive, diffTopicFinished и diffEventEnded указано в милисекундах
  diffTopicLive: 600000,
  diffTopicFinished: 10800000,

  // Время в миллисекундах. Если после начала последнего топика
  // прошло это время - ивент считается завершённым
  // по-умолчанию 108000000 = 30 часов
  diffEventEnded: 108000000,

  // ! Автовебинары
  autoWebinars: {
    allowRedirect: false, // True, если условия для редиректа выполнены,
    activeTopicAxlWebinarId: null,
    subscriberEmail: null,
  },

  // ! Данные страницы
  buzy: true,

  // Мобильный просмотр стрима во весь экран
  // Открывается по нажатию кнопки в компоненте ChatButton.vue
  displayMobileView: false,

  // Диалог регистрации на вебинар
  displayRegisterDialog: false,

  // Подписан ли текущий пользователь на ивент
  isSubscribed: false,

  // Ссылка на фрейм с онлайн трансляцией
  streamUrl: '',

  // Ссылка на фрейм с чатом для онлайн трансляции
  chatUrl: '',

  // ! Данные текущего ивента
  data: null,

  // Выбранный топик
  topic: null,

  // Статус всего ивента
  // Может быть upcoming, started и ended
  // Если у ивента не стартовал ещё ни один топик
  // ивент считается не начавшимся, показывается форма регистрации
  // и обложка ивента. Селект с выбором дня не появляется.
  // Если один из топиков в статусе live или finished - ивент started.
  // Если все топики в статусе finished - ивент ended.
  eventStatus: '',

  // Массив статусов топиков.
  // Если в активной вкладке браузера ивент начинается\заканчивается
  // Значения меняются именно в этом массиве, а не в самом объекте ивента
  // Статус топика Может быть upcoming, live и finished
  topicsStatuses: [],

  // Регистрация закрывается, когда последний топик завершился
  closeRegistration: false,
});

export const getters = {
  // Статус текущего выбранного топика
  topicStatus: (state) => {
    const num = state.topic.num;
    return state.topicsStatuses[num];
  },
  // Статус активного на сервере топика
  activeTopicStatus: (state) => {
    const num = state.data?.activeTopic?.num;
    return state.topicsStatuses[num];
  },
  // Статус топика по индексу
  topicStatusByNum: (state) => (num) => {
    return state.topicsStatuses[num];
  },
};

export const mutations = {
  /**  Стейт загрузки всей страницы */
  SET_BUZY(state, payload) {
    state.buzy = payload;
  },
  /** Включить или выключить мобильный просмотр стрима */
  SET_DISPLAY_MOBILE_VIEW(state, payload) {
    state.displayMobileView = payload;
  },
  /** Установить данные текущего ивента */
  SET_DATA(state, payload) {
    state.data = payload;
  },
  /** Сменить выбранный топик. При смене топика меняем и текущий статус топика */
  SELECT_TOPIC(state, payload) {
    state.topic = payload;
  },
  SELECT_TOPIC_BY_NUM(state, num) {
    const topic = state.data.topics.find((i) => i.num === num);
    state.topic = topic;
    state.topicStatus = topic.status;
  },

  SET_DISPLAY_REGISTER_DIALOG(state, payload) {
    state.displayRegisterDialog = payload;
  },
  // Создаётся в компоненте chat.vue
  SET_CHAT_URL(state, payload) {
    state.chatUrl = payload;
  },
  // Создаётся в компоненте stream.vue
  SET_STREAM_URL(state, payload) {
    state.streamUrl = payload;
  },
  SET_EVENT_STATUS(state, payload) {
    state.eventStatus = payload;
  },
  SET_IS_SUBSCRIBED(state, payload) {
    state.isSubscribed = payload;
  },
  SET_TOPICS_STATUSES(state, payload) {
    state.topicsStatuses = payload;
  },
  UPDATE_TOPIC_STATUS_BY_NUM(state, { num, status }) {
    Vue.set(state.topicsStatuses, num, status);
  },
  SET_CLOSE_REGISTRATION(state, payload) {
    state.closeRegistration = payload;
  },

  // Автовебинары
  SET_AUTO_WEBINARS_ALLOW_REDIRECT(state, payload) {
    state.autoWebinars.allowRedirect = payload;
  },
  SET_AUTO_WEBINARS_ACTIVE_TOPIC_AXL_WEBINAR_ID(state, payload) {
    state.autoWebinars.activeTopicAxlWebinarId = payload;
  },
  SET_AUTO_WEBINARS_SUBSCRIBER_EMAIL(state, payload) {
    state.autoWebinars.subscriberEmail = payload;
  },
};

export const actions = {
  /** Получить данные ивента из CMS */
  async GET_EVENT_DATA({ commit, dispatch }, slug) {
    // Сбросить данные от прошлых ивентов
    commit('SET_CHAT_URL', '');
    commit('SET_STREAM_URL', '');
    commit('SET_IS_SUBSCRIBED', false);
    commit('SET_CLOSE_REGISTRATION', false);

    // По неизвестным мне причинам nuxt триггерит fetch ивент
    // когда на странице завершает загрузку iframe с чатом
    // и slug меняется на промис
    // Чтобы исключить лишние запросы, это отфильтровывается
    if (slug === '[object Promise]') return;

    commit('SET_BUZY', true);
    const data = await fetchEvent.bind(this)(slug);

    // Ивент не найден
    if (!data) return false;

    commit('SET_DATA', data);

    // По-умолчанию выбранным топиком выбирается активный топик
    commit('SELECT_TOPIC', data.activeTopic);
    commit('SET_BUZY', false);

    // При получении ивента задаём статус топика и всего ивента
    await dispatch('UPDATE_TOPICS_STATUSES');

    // Автовебинары
    dispatch('SET_AUTO_WEBINARS_REDIRECT_DATA');
  },
  /** Установить статус для одного топика вручную и обновить статус ивента */
  UPDATE_TOPIC_STATUS({ commit, dispatch }, { num, status }) {
    commit('UPDATE_TOPIC_STATUS_BY_NUM', { num, status });
    dispatch('UPDATE_EVENT_STATUS');
  },
  /** Установить статусы всех топиков и статус ивента */
  async UPDATE_TOPICS_STATUSES({ dispatch }) {
    await dispatch('SET_TOPICS_STATUSES');
    dispatch('UPDATE_EVENT_STATUS');
  },
  /** Если меняется статус топиков,
   *  нужно обновить статус самого ивента
   */
  UPDATE_EVENT_STATUS({ state, commit, getters }) {
    const activeTopic = state.data?.activeTopic;
    const activeTopicStatus = getters['activeTopicStatus'];
    let status = 'started';

    // Если активный топик - самый первый и он upcoming
    // Значит ивент ещё не начался
    if (activeTopicStatus === 'upcoming' && activeTopic.num === 0) {
      status = 'upcoming';
      commit('SET_CLOSE_REGISTRATION', false);
    }

    // Если активный топик не первый и не завершён - значит ивент
    // начался, но не завершился
    if (activeTopicStatus !== 'finished' && activeTopic.num > 0) {
      status = 'started';
      commit('SET_CLOSE_REGISTRATION', false);
    }

    // Если активный топик последний и завершён
    // Нужно проверить, сколько времени прошло с начала последнего топика
    // Мы считаем, что ивент finished, когда с момента старта
    // последнего топика прошло 30 часов
    const topicsCount = state.data?.topics?.length;
    if (
      activeTopicStatus === 'finished' &&
      activeTopic.num === topicsCount - 1
    ) {
      // Если 30 часов не прошло, но последний топик уже начался или закончился
      // ивент будет со статусом started и баннер под видео не пропадёт
      commit('SET_CLOSE_REGISTRATION', true);
      status = 'started';

      const started = activeTopic.topicDateUtc;
      const startedDate = this.$dayjs(started);
      const now = this.$dayjs();

      const diffTime = startedDate.diff(now);
      if (diffTime < -state.diffEventEnded) {
        status = 'ended';
      }
    }

    if (state.eventStatus === status) return;
    commit('SET_EVENT_STATUS', status);
  },
  /** Получить статус активного топика */
  async CHECK_ACTIVE_TOPIC_STATUS({ state }) {
    // Оффсеты времени в миллисекундах
    const diffTopicLive = state.diffTopicLive;
    const diffTopicFinished = state.diffTopicFinished;

    const activeTopic = state.data?.activeTopic;
    if (!activeTopic) return;

    const now = this.$dayjs.utc();

    const startDate = this.$dayjs(activeTopic.topicDateUtc);
    const diffTime = startDate.diff(now);

    // Если со старта топика прошло 2 часа - отмечаем его как finished
    if (diffTime < -diffTopicFinished) {
      return 'finished';
    }

    // Если до старта топика осталось 10 минут - отмечаем его как live
    if (diffTime < diffTopicLive) {
      return 'live';
    }

    return 'upcoming';
  },

  /** Проставить статусы всех топиков */
  async SET_TOPICS_STATUSES({ state, commit, dispatch }) {
    // Установить статусы прошедших топиков
    const activeTopicNum = state.data.activeTopic?.num;

    const topics = state.data.topics;
    const statuses = topics.map((i) => {
      if (i.num < activeTopicNum) return 'finished';
      return 'upcoming';
    });

    commit('SET_TOPICS_STATUSES', statuses);

    // Установить статус активного топика
    const activeTopicStatus = await dispatch('CHECK_ACTIVE_TOPIC_STATUS');
    commit('UPDATE_TOPIC_STATUS_BY_NUM', {
      num: activeTopicNum,
      status: activeTopicStatus,
    });
  },

  // Получить статус подписки на ивент из лмс
  async GET_SUBSCRIPTION({ commit }, { a_token, slug }) {
    if (!a_token) return false;

    const getSubscriptionFunction = getSubscription.bind(this);
    const result = await getSubscriptionFunction(a_token, slug);
    commit('SET_IS_SUBSCRIBED', !!result);

    return result;
  },

  // Автовебинары
  SET_AUTO_WEBINARS_REDIRECT_DATA({ state, commit }) {
    if (state.data?.activeTopic?.axlWebinarId) {
      commit(
        'SET_AUTO_WEBINARS_ACTIVE_TOPIC_AXL_WEBINAR_ID',
        state.data.activeTopic.axlWebinarId,
      );
    } else {
      commit('SET_AUTO_WEBINARS_ALLOW_REDIRECT', false);
      commit('SET_AUTO_WEBINARS_ACTIVE_TOPIC_AXL_WEBINAR_ID', null);
    }
  },
  SET_AUTO_WEBINARS_ALLOW_REDIRECT_BY_CONDITION({
    state,
    commit,
    dispatch,
    getters,
  }) {
    const activeTopicStatus = getters['activeTopicStatus'];
    if (state.isSubscribed && activeTopicStatus === 'live') {
      commit('SET_AUTO_WEBINARS_ALLOW_REDIRECT', true);
      dispatch('ATTEMPTED_REDIRECT_TO_AUTO_WEBINAR');
    } else {
      commit('SET_AUTO_WEBINARS_ALLOW_REDIRECT', false);
    }
  },
  async ATTEMPTED_REDIRECT_TO_AUTO_WEBINAR({ state, commit }) {
    if (
      state.autoWebinars.allowRedirect &&
      state.autoWebinars.activeTopicAxlWebinarId &&
      (this.$app.user?.email || state.autoWebinars.subscriberEmail)
    ) {
      commit('SET_LOADING_PAGE_UP', null, { root: true });
      const loginLink = await getLoginLinkAutoWebinar(this, {
        a_token: getCookie({ name: 'a_token', cookies: document.cookie }),
        email: this.$app.user?.email || state.autoWebinars.subscriberEmail,
        axlWebinarId: state.autoWebinars.activeTopicAxlWebinarId,
      });
      if (!loginLink) {
        commit('SET_LOADING_PAGE_DOWN', null, { root: true });
      } else {
        window.location.href = loginLink;
      }
    }
  },
  SET_SUBCRIBER_EMAIL_BY_FORM({ commit }, email) {
    commit('SET_AUTO_WEBINARS_SUBSCRIBER_EMAIL', email);
  },
};

async function fetchEvent(slug) {
  try {
    const response = await this.$axios({
      method: 'get',
      url: `${this.$config.CMS_REST_API}/public/v2/event`,
      params: {
        slug,
      },
    });

    return response.data.data;
  } catch (e) {
    console.error('fetchEvent error: ', e);
    return false;
  }
}

async function getLoginLinkAutoWebinar(vm, { a_token, email, axlWebinarId }) {
  try {
    const {
      data: { loginLink },
    } = await vm.$axios({
      method: 'post',
      url: vm.$app.config.current.autoWebinarLoginLinkAws,
      data: {
        a_token,
        email,
        axlWebinarId,
        country: vm.$app.config.current.code,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'OPTIONS,POST',
        'Access-Control-Allow-Headers': '*',
      },
    });
    return loginLink;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getSubscription(a_token, slug) {
  try {
    const response = await this.$axios({
      method: 'get',
      url: this.$app.config.current.checkSubscriptionsLink,
      params: {
        webinarSlug: slug,
      },
      headers: { Authorization: `Bearer ${a_token}` },
    });

    return response.data.isMine;
  } catch (e) {
    console.error(e);
    return null;
  }
}
