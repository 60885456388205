import Vue from 'vue';

/**
 * Добавляем объект modals к Vue.prototype.$root и устанавливаем наблюдателя
 * Это необходимо для единого механизма блокировки скролла
 * */
export default ({ store }) => {
  Vue.use({
    install(Vue) {
      /**
       * С этим свойством работают диалоговые окна в ui kit
       * Поэтому привязываемся к нему же
       * */
      Vue.prototype.modals = Vue.observable({ openedModals: [] });

      new Vue({
        watch: {
          'modals.openedModals'(value) {
            if (value.length) {
              store.dispatch('ui/scroll/lockScroll');
            } else {
              store.dispatch('ui/scroll/unlockScroll');
            }
          },
        },
      });
    },
  });
};
