//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { VueMaskDirective } from '../../directives/v-mask.esm';
import { ValidationProvider } from 'vee-validate';

import AngleDown from '../../../landing_generator/assets/svg/angle-down.svg?inline';

export default {
  name: 'Phone',
  directives: {
    mask: VueMaskDirective,
  },
  components: {
    AngleDown,
    ValidationProvider,
  },
  props: ['value', 'name', 'defaultPhoneMask', 'errorMessages', 'disabled'],
  data() {
    return {
      phoneMaskName:
        this.defaultPhoneMask ||
        this.$app.config.current.defaultPhoneMask ||
        'bm',
      attach: null,
      isMenuActive: false,
      PHONE_MASK_COUNTRIES_LIST: {
        af: {
          n: 'Afghanistan (افغانستان‎)',
          c: '+93',
          m: '+93-99-999-9999',
          id: 't-phonemask_af',
        },
        al: {
          n: 'Albania (Shqipëri)',
          c: '+355',
          m: '+355(999) 999-999',
          id: 't-phonemask_al',
        },
        dz: {
          n: 'Algeria (الجزائر‎)',
          c: '+213',
          m: '+213-99-999-9999',
          id: 't-phonemask_dz',
        },
        ad: {
          n: 'Andorra',
          c: '+376',
          m: '+376-999-999',
          id: 't-phonemask_ad',
        },
        ao: {
          n: 'Angola',
          c: '+244',
          m: '+244(999) 999-999',
          id: 't-phonemask_ao',
        },
        am: {
          n: 'Armenia (Հայաստան)',
          c: '+374',
          m: '+374-99-999-999',
          id: 't-phonemask_am',
        },
        ag: {
          n: 'Antigua and Barbuda',
          c: '+1 (268)',
          m: '+1 (268)999-9999',
          id: 't-phonemask_ag',
        },
        ar: {
          n: 'Argentina',
          c: '+54',
          m: '+54(999) 9999-9999',
          id: 't-phonemask_ar',
        },
        au: {
          n: 'Australia',
          c: '+61',
          m: '+61-99-9999-9999',
          id: 't-phonemask_au',
        },
        at: {
          n: 'Austria (Österreich)',
          c: '+43',
          m: '+43(999) 999-99999',
          id: 't-phonemask_at',
        },
        az: {
          n: 'Azerbaijan (Azərbaycan)',
          c: '+994',
          m: '+994-99-999-99-99',
          id: 't-phonemask_az',
        },
        bs: {
          n: 'Bahamas',
          c: '+1 (242)',
          m: '+1 (242)999-9999',
          id: 't-phonemask_bs',
        },
        bh: {
          n: 'Bahrain (البحرين‎)',
          c: '+973',
          m: '+973-9999-9999',
          id: 't-phonemask_bh',
        },
        bd: {
          n: 'Bangladesh (বাংলাদেশ)',
          c: '+880',
          m: '+880-9999-999999',
          id: 't-phonemask_bd',
        },
        bb: {
          n: 'Barbados',
          c: '+1 (246)',
          m: '+1 (246)999-9999',
          id: 't-phonemask_bb',
        },
        be: {
          n: 'Belgium (België)',
          c: '+32',
          m: '+32(999) 999-999',
          id: 't-phonemask_be',
        },
        bz: {
          n: 'Belize',
          c: '+501',
          m: '+501-999-9999',
          id: 't-phonemask_bz',
        },
        bj: {
          n: 'Benin (Bénin)',
          c: '+229',
          m: '+229-99-99-9999',
          id: 't-phonemask_bj',
        },
        bt: {
          n: 'Bhutan',
          c: '+975',
          m: '+975-9-999-9999',
          id: 't-phonemask_bt',
        },
        bo: {
          n: 'Bolivia',
          c: '+591',
          m: '+591-9-999-9999',
          id: 't-phonemask_bo',
        },
        ba: {
          n: 'Bosnia and Herzegovina',
          c: '+387',
          m: '+387-99-999-999',
          id: 't-phonemask_ba',
        },
        bw: {
          n: 'Botswana',
          c: '+267',
          m: '+267-99-999-999',
          id: 't-phonemask_bw',
        },
        br: {
          n: 'Brazil (Brasil)',
          c: '+55',
          m: '+55(99) 9999-9999',
          id: 't-phonemask_br',
        },
        bm: {
          n: 'Brazil (Brasil) (mobile)',
          c: '+55',
          m: '+55(99) 99999-9999',
          id: 't-phonemask_bm',
        },
        bn: {
          n: 'Brunei',
          c: '+673',
          m: '+673-999-9999',
          id: 't-phonemask_bn',
        },
        bg: {
          n: 'Bulgaria (България)',
          c: '+359',
          m: '+359(999) 999-999',
          id: 't-phonemask_bg',
        },
        bf: {
          n: 'Burkina Faso',
          c: '+226',
          m: '+226-99-99-9999',
          id: 't-phonemask_bf',
        },
        kh: {
          n: 'Cambodia',
          c: '+855',
          m: '+855-99-999-999',
          id: 't-phonemask_kh',
        },
        cm: {
          n: 'Cameroon (Cameroun)',
          c: '+237',
          m: '+237-9-99-99-99-99',
          id: 't-phonemask_cm',
        },
        ca: {
          n: 'Canada',
          c: '+1',
          m: '+1(999) 999-9999',
          id: 't-phonemask_ca',
        },
        cv: {
          n: 'Cape Verde (Kabu Verdi)',
          c: '+238',
          m: '+238(999) 99-99',
          id: 't-phonemask_cv',
        },
        td: {
          n: 'Chad (Tchad)',
          c: '+235',
          m: '+235-99-99-99-99',
          id: 't-phonemask_td',
        },
        cl: {
          n: 'Chile',
          c: '+56',
          m: '+56-9-9999-9999',
          id: 't-phonemask_cl',
        },
        cn: {
          n: 'China (中国)',
          c: '+86',
          m: '+86(999) 9999-9999',
          id: 't-phonemask_cn',
        },
        co: {
          n: 'Colombia',
          c: '+57',
          m: '+57(999) 999-9999',
          id: 't-phonemask_co',
        },
        km: {
          n: 'Comoros (جزر القمر‎)',
          c: '+269',
          m: '+269-99-99999',
          id: 't-phonemask_km',
        },
        ck: {
          n: 'Cook Islands',
          c: '+682',
          m: '+682-99-999',
          id: 't-phonemask_ck',
        },
        cr: {
          n: 'Costa Rica',
          c: '+506',
          m: '+506-9999-9999',
          id: 't-phonemask_cr',
        },
        ci: {
          n: 'Cote d’Ivoire',
          c: '+225',
          m: '+225-99-999-999',
          id: 't-phonemask_ci',
        },
        hr: {
          n: 'Croatia (Hrvatska)',
          c: '+385',
          m: '+385-99-999-9999',
          id: 't-phonemask_hr',
        },
        cy: {
          n: 'Cyprus (Κύπρος)',
          c: '+357',
          m: '+357-99-999-999',
          id: 't-phonemask_cy',
        },
        cz: {
          n: 'Czech Republic (Česká republika)',
          c: '+420',
          m: '+420(999) 999-999',
          id: 't-phonemask_cz',
        },
        dk: {
          n: 'Denmark (Danmark)',
          c: '+45',
          m: '+45-99-99-99-99',
          id: 't-phonemask_dk',
        },
        dj: {
          n: 'Djibouti',
          c: '+253',
          m: '+253-99-99-99-99',
          id: 't-phonemask_dj',
        },
        dm: {
          n: 'Dominica',
          c: '+1 (767)',
          m: '+1 (767)999-9999',
          id: 't-phonemask_dm',
        },
        do: {
          n: 'Dominican Republic (República Dominicana)',
          c: '+1',
          m: '+1(999) 999-9999',
          id: 't-phonemask_do',
        },
        ec: {
          n: 'Ecuador',
          c: '+593',
          m: '+593-9-999-9999',
          id: 't-phonemask_ec',
        },
        eg: {
          n: 'Egypt (مصر‎)',
          c: '+20',
          m: '+20(999) 999-9999',
          id: 't-phonemask_eg',
        },
        sv: {
          n: 'El Salvador',
          c: '+503',
          m: '+503-99-99-9999',
          id: 't-phonemask_sv',
        },
        gq: {
          n: 'Equatorial Guinea (Guinea Ecuatorial)',
          c: '+240',
          m: '+240-99-999-9999',
          id: 't-phonemask_gq',
        },
        er: {
          n: 'Eritrea',
          c: '+291',
          m: '+291-9-999-999',
          id: 't-phonemask_er',
        },
        ee: {
          n: 'Estonia (Eesti)',
          c: '+372',
          m: '+372-9999-9999',
          id: 't-phonemask_ee',
        },
        et: {
          n: 'Ethiopia',
          c: '+251',
          m: '+251-99-999-9999',
          id: 't-phonemask_et',
        },
        fj: { n: 'Fiji', c: '+679', m: '+679-999-9999', id: 't-phonemask_fj' },
        fi: {
          n: 'Finland (Suomi)',
          c: '+358',
          m: '+358(999) 999-99-99',
          id: 't-phonemask_fi',
        },
        fr: {
          n: 'France',
          c: '+33',
          m: '+33(999) 999-999',
          id: 't-phonemask_fr',
        },
        ga: {
          n: 'Gabon',
          c: '+241',
          m: '+241-9-99-99-99',
          id: 't-phonemask_ga',
        },
        gm: {
          n: 'Gambia',
          c: '+220',
          m: '+220(999) 99-99',
          id: 't-phonemask_gm',
        },
        ge: {
          n: 'Georgia (საქართველო)',
          c: '+995',
          m: '+995(999) 999-999',
          id: 't-phonemask_ge',
        },
        de: {
          n: 'Germany (Deutschland)',
          c: '+49',
          m: '+49(999) 999-99999',
          id: 't-phonemask_de',
        },
        gh: {
          n: 'Ghana (Gaana)',
          c: '+233',
          m: '+233(999) 999-999',
          id: 't-phonemask_gh',
        },
        gr: {
          n: 'Greece (Ελλάδα)',
          c: '+30',
          m: '+30(999) 999-9999',
          id: 't-phonemask_gr',
        },
        gd: {
          n: 'Grenada',
          c: '+1 (473)',
          m: '+1 (473)999-9999',
          id: 't-phonemask_gd',
        },
        gt: {
          n: 'Guatemala',
          c: '+502',
          m: '+502-9-999-9999',
          id: 't-phonemask_gt',
        },
        gn: {
          n: 'Guinea (Guinée)',
          c: '+224',
          m: '+224-999-99-99-99',
          id: 't-phonemask_gn',
        },
        gw: {
          n: 'Guinea-Bissau (Guiné Bissau)',
          c: '+245',
          m: '+245-9-999999',
          id: 't-phonemask_gw',
        },
        gy: {
          n: 'Guyana',
          c: '+592',
          m: '+592-999-9999',
          id: 't-phonemask_gy',
        },
        ht: {
          n: 'Haiti',
          c: '+509',
          m: '+509-99-99-9999',
          id: 't-phonemask_ht',
        },
        hn: {
          n: 'Honduras',
          c: '+504',
          m: '+504-9999-9999',
          id: 't-phonemask_hn',
        },
        hk: {
          n: 'Hong Kong (香港)',
          c: '+852',
          m: '+852-9999-9999',
          id: 't-phonemask_hk',
        },
        hu: {
          n: 'Hungary (Magyarország)',
          c: '+36',
          m: '+36(999) 999-999',
          id: 't-phonemask_hu',
        },
        is: {
          n: 'Iceland (Ísland)',
          c: '+354',
          m: '+354-999-9999',
          id: 't-phonemask_is',
        },
        in: {
          n: 'India (भारत)',
          c: '+91',
          m: '+91(9999) 999-999',
          id: 't-phonemask_in',
        },
        id: {
          n: 'Indonesia',
          c: '+62',
          m: '+62(999) 999-99-999',
          id: 't-phonemask_id',
        },
        ie: {
          n: 'Ireland',
          c: '+353',
          m: '+353(999) 999-999',
          id: 't-phonemask_ie',
        },
        il: {
          n: 'Israel (ישראל‎)',
          c: '+972',
          m: '+972-9-999-99999',
          id: 't-phonemask_il',
        },
        it: {
          n: 'Italy (Italia)',
          c: '+39',
          m: '+39(999) 9999-999',
          id: 't-phonemask_it',
        },
        jm: {
          n: 'Jamaica',
          c: '+1',
          m: '+1(999) 999-9999',
          id: 't-phonemask_jm',
        },
        jp: {
          n: 'Japan (日本)',
          c: '+81',
          m: '+81-99-9999-9999',
          id: 't-phonemask_jp',
        },
        jo: {
          n: 'Jordan (الأردن‎)',
          c: '+962',
          m: '+962-9-9999-9999',
          id: 't-phonemask_jo',
        },
        kz: {
          n: 'Kazakhstan (Казахстан)',
          c: '+7',
          m: '+7(999) 999-99-99',
          id: 't-phonemask_kz',
        },
        ke: {
          n: 'Kenya',
          c: '+254',
          m: '+254-999-999999',
          id: 't-phonemask_ke',
        },
        ki: {
          n: 'Kiribati',
          c: '+686',
          m: '+686-99-999',
          id: 't-phonemask_ki',
        },
        kw: {
          n: 'Kuwait (الكويت‎)',
          c: '+965',
          m: '+965-9999-9999',
          id: 't-phonemask_kw',
        },
        kg: {
          n: 'Kyrgyzstan (Кыргызстан)',
          c: '+996',
          m: '+996(999) 999-999',
          id: 't-phonemask_kg',
        },
        la: {
          n: 'Laos',
          c: '+856',
          m: '+856-99-999-999',
          id: 't-phonemask_la',
        },
        lv: {
          n: 'Latvia (Latvija)',
          c: '+371',
          m: '+371-99-999-999',
          id: 't-phonemask_lv',
        },
        ls: {
          n: 'Lesotho',
          c: '+266',
          m: '+266-9-999-9999',
          id: 't-phonemask_ls',
        },
        lr: {
          n: 'Liberia',
          c: '+231',
          m: '+231-99-999-9999',
          id: 't-phonemask_lr',
        },
        li: {
          n: 'Liechtenstein',
          c: '+423',
          m: '+423-999-99-99',
          id: 't-phonemask_li',
        },
        lt: {
          n: 'Lithuania (Lietuva)',
          c: '+370',
          m: '+370(999) 99-999',
          id: 't-phonemask_lt',
        },
        lu: {
          n: 'Luxembourg',
          c: '+352',
          m: '+352(999) 999-999',
          id: 't-phonemask_lu',
        },
        mk: {
          n: 'Macedonia (FYROM) (Македонија)',
          c: '+389',
          m: '+389-99-999-999',
          id: 't-phonemask_mk',
        },
        mg: {
          n: 'Madagascar (Madagasikara)',
          c: '+261',
          m: '+261-99-99-99999',
          id: 't-phonemask_mg',
        },
        mw: {
          n: 'Malawi',
          c: '+265',
          m: '+265-9-9999-9999',
          id: 't-phonemask_mw',
        },
        my: {
          n: 'Malaysia',
          c: '+60',
          m: '+60-99-999-9999',
          id: 't-phonemask_my',
        },
        mv: {
          n: 'Maldives',
          c: '+960',
          m: '+960-999-9999',
          id: 't-phonemask_mv',
        },
        mt: {
          n: 'Malta',
          c: '+356',
          m: '+356-9999-9999',
          id: 't-phonemask_mt',
        },
        mh: {
          n: 'Marshall Islands',
          c: '+692',
          m: '+692-999-9999',
          id: 't-phonemask_mh',
        },
        mr: {
          n: 'Mauritania (موريتانيا‎)',
          c: '+222',
          m: '+222-99-99-9999',
          id: 't-phonemask_mr',
        },
        mu: {
          n: 'Mauritius (Moris)',
          c: '+230',
          m: '+230-999-9999',
          id: 't-phonemask_mu',
        },
        mx: {
          n: 'Mexico (México)',
          c: '+52',
          m: '+52(999) 999-9999',
          id: 't-phonemask_mx',
        },
        fm: {
          n: 'Micronesia',
          c: '+691',
          m: '+691-999-9999',
          id: 't-phonemask_fm',
        },
        md: {
          n: 'Moldova (Republica Moldova)',
          c: '+373',
          m: '+373-9999-9999',
          id: 't-phonemask_md',
        },
        mc: {
          n: 'Monaco',
          c: '+377',
          m: '+377-99-999-999',
          id: 't-phonemask_mc',
        },
        mn: {
          n: 'Mongolia (Монгол)',
          c: '+976',
          m: '+976-99-99-9999',
          id: 't-phonemask_mn',
        },
        me: {
          n: 'Montenegro (Crna Gora)',
          c: '+382',
          m: '+382-99-999-999',
          id: 't-phonemask_me',
        },
        ma: {
          n: 'Morocco (المغرب‎)',
          c: '+212',
          m: '+212-99-9999-999',
          id: 't-phonemask_ma',
        },
        mz: {
          n: 'Mozambique (Moçambique)',
          c: '+258',
          m: '+258-99-999-999',
          id: 't-phonemask_mz',
        },
        mm: {
          n: 'Myanmar (Burma)',
          c: '+95',
          m: '+95-99-999-999',
          id: 't-phonemask_mm',
        },
        na: {
          n: 'Namibia (Namibië)',
          c: '+264',
          m: '+264-99-999-9999',
          id: 't-phonemask_na',
        },
        nr: { n: 'Nauru', c: '+674', m: '+674-999-9999', id: 't-phonemask_nr' },
        np: {
          n: 'Nepal (नेपाल)',
          c: '+977',
          m: '+977-99-999-999',
          id: 't-phonemask_np',
        },
        nl: {
          n: 'Netherlands (Nederland)',
          c: '+31',
          m: '+31-99-999-9999',
          id: 't-phonemask_nl',
        },
        nz: {
          n: 'New Zealand',
          c: '+64',
          m: '+64(999) 999-999',
          id: 't-phonemask_nz',
        },
        ne: {
          n: 'Niger (Nijar)',
          c: '+227',
          m: '+227-99-99-9999',
          id: 't-phonemask_ne',
        },
        ng: {
          n: 'Nigeria',
          c: '+234',
          m: '+234-99-999-999',
          id: 't-phonemask_ng',
        },
        nu: { n: 'Niue', c: '+683', m: '+683-9999', id: 't-phonemask_nu' },
        no: {
          n: 'Norway (Norge)',
          c: '+47',
          m: '+47(999) 99-999',
          id: 't-phonemask_no',
        },
        om: {
          n: 'Oman (عُمان‎)',
          c: '+968',
          m: '+968-99-999-999',
          id: 't-phonemask_om',
        },
        pk: {
          n: 'Pakistan (پاکستان‎)',
          c: '+92',
          m: '+92(999) 999-9999',
          id: 't-phonemask_pk',
        },
        pw: { n: 'Palau', c: '+680', m: '+680-999-9999', id: 't-phonemask_pw' },
        ps: {
          n: 'Palestinian Territory',
          c: '+970',
          m: '+970 99 999 9999',
          id: 't-phonemask_ps',
        },
        pg: {
          n: 'Papua New Guinea',
          c: '+675',
          m: '+675(999) 99-999',
          id: 't-phonemask_pg',
        },
        py: {
          n: 'Paraguay',
          c: '+595',
          m: '+595(999) 999-999',
          id: 't-phonemask_py',
        },
        pe: {
          n: 'Peru (Perú)',
          c: '+51',
          m: '+51(999) 999-999',
          id: 't-phonemask_pe',
        },
        ph: {
          n: 'Philippines',
          c: '+63',
          m: '+63(999) 999-9999',
          id: 't-phonemask_ph',
        },
        pl: {
          n: 'Poland (Polska)',
          c: '+48',
          m: '+48(999) 999-999',
          id: 't-phonemask_pl',
        },
        pt: {
          n: 'Portugal',
          c: '+351',
          m: '+351-99-999-9999',
          id: 't-phonemask_pt',
        },
        qa: {
          n: 'Qatar (قطر‎)',
          c: '+974',
          m: '+974-9999-9999',
          id: 't-phonemask_qa',
        },
        ro: {
          n: 'Romania (România)',
          c: '+40',
          m: '+40-99-999-9999',
          id: 't-phonemask_ro',
        },
        rw: {
          n: 'Rwanda',
          c: '+250',
          m: '+250(999) 999-999',
          id: 't-phonemask_rw',
        },
        kn: {
          n: 'Saint Kitts and Nevis',
          c: '+1 (869)',
          m: '+1 (869)999-9999',
          id: 't-phonemask_kn',
        },
        lc: {
          n: 'Saint Lucia',
          c: '+1 (758)',
          m: '+1 (758)999-9999',
          id: 't-phonemask_lc',
        },
        vc: {
          n: 'Saint Vincent and the Grenadines',
          c: '+1 (784)',
          m: '+1 (784)999-9999',
          id: 't-phonemask_vc',
        },
        ws: { n: 'Samoa', c: '+685', m: '+685-99-9999', id: 't-phonemask_ws' },
        sm: {
          n: 'San Marino',
          c: '+378',
          m: '+378-9999-999999',
          id: 't-phonemask_sm',
        },
        st: {
          n: 'Sao Tome and Principe (São Tomé e Príncipe)',
          c: '+239',
          m: '+239-99-99999',
          id: 't-phonemask_st',
        },
        sa: {
          n: 'Saudi Arabia (المملكة العربية السعودية‎)',
          c: '+966',
          m: '+966-9-9999-9999',
          id: 't-phonemask_sa',
        },
        sn: {
          n: 'Senegal (Sénégal)',
          c: '+221',
          m: '+221-99-999-9999',
          id: 't-phonemask_sn',
        },
        rs: {
          n: 'Serbia (Србија)',
          c: '+381',
          m: '+381-99-999-9999',
          id: 't-phonemask_rs',
        },
        sc: {
          n: 'Seychelles',
          c: '+248',
          m: '+248-9-999-999',
          id: 't-phonemask_sc',
        },
        sl: {
          n: 'Sierra Leone',
          c: '+232',
          m: '+232-99-999999',
          id: 't-phonemask_sl',
        },
        sg: {
          n: 'Singapore',
          c: '+65',
          m: '+65-9999-9999',
          id: 't-phonemask_sg',
        },
        sk: {
          n: 'Slovakia (Slovensko)',
          c: '+421',
          m: '+421(999) 999-999',
          id: 't-phonemask_sk',
        },
        si: {
          n: 'Slovenia (Slovenija)',
          c: '+386',
          m: '+386-99-999-999',
          id: 't-phonemask_si',
        },
        sb: {
          n: 'Solomon Islands',
          c: '+677',
          m: '+677-999-9999',
          id: 't-phonemask_sb',
        },
        za: {
          n: 'South Africa',
          c: '+27',
          m: '+27-99-999-9999',
          id: 't-phonemask_za',
        },
        kr: {
          n: 'South Korea',
          c: '+82',
          m: '+82-99-9999-9999',
          id: 't-phonemask_kr',
        },
        es: {
          n: 'Spain (España)',
          c: '+34',
          m: '+34(999) 999-999',
          id: 't-phonemask_es',
        },
        lk: {
          n: 'Sri Lanka (ශ්‍රී ලංකාව)',
          c: '+94',
          m: '+94-99-999-9999',
          id: 't-phonemask_lk',
        },
        sr: {
          n: 'Suriname',
          c: '+597',
          m: '+597-999-9999',
          id: 't-phonemask_sr',
        },
        sz: {
          n: 'Swaziland',
          c: '+268',
          m: '+268-99-99-9999',
          id: 't-phonemask_sz',
        },
        se: {
          n: 'Sweden (Sverige)',
          c: '+46',
          m: '+46-99-999-9999',
          id: 't-phonemask_se',
        },
        ch: {
          n: 'Switzerland (Schweiz)',
          c: '+41',
          m: '+41-99-999-9999',
          id: 't-phonemask_ch',
        },
        tw: {
          n: 'Taiwan (台灣)',
          c: '+886',
          m: '+886-9999-9999',
          id: 't-phonemask_tw',
        },
        tj: {
          n: 'Tajikistan',
          c: '+992',
          m: '+992-99-999-9999',
          id: 't-phonemask_tj',
        },
        tz: {
          n: 'Tanzania',
          c: '+255',
          m: '+255-99-999-9999',
          id: 't-phonemask_tz',
        },
        th: {
          n: 'Thailand (ไทย)',
          c: '+66',
          m: '+66-99-999-9999',
          id: 't-phonemask_th',
        },
        tg: {
          n: 'Togo',
          c: '+228',
          m: '+228-99-999-999',
          id: 't-phonemask_tg',
        },
        to: { n: 'Tonga', c: '+676', m: '+676-99999', id: 't-phonemask_to' },
        tt: {
          n: 'Trinidad and Tobago',
          c: '+1 (868)',
          m: '+1 (868)999-9999',
          id: 't-phonemask_tt',
        },
        tn: {
          n: 'Tunisia (تونس‎)',
          c: '+216',
          m: '+216-99-999-999',
          id: 't-phonemask_tn',
        },
        tr: {
          n: 'Turkey (Türkiye)',
          c: '+90',
          m: '+90(999) 999-9999',
          id: 't-phonemask_tr',
        },
        tm: {
          n: 'Turkmenistan',
          c: '+993',
          m: '+993-9-999-9999',
          id: 't-phonemask_tm',
        },
        tv: { n: 'Tuvalu', c: '+688', m: '+688-999999', id: 't-phonemask_tv' },
        ug: {
          n: 'Uganda',
          c: '+256',
          m: '+256(999) 999-999',
          id: 't-phonemask_ug',
        },
        ae: {
          n: 'United Arab Emirates (الإمارات العربية المتحدة‎)',
          c: '+971',
          m: '+971-99-999-9999',
          id: 't-phonemask_ae',
        },
        gb: {
          n: 'United Kingdom',
          c: '+44',
          m: '+44-99-9999-99999',
          id: 't-phonemask_gb',
        },
        us: { n: 'USA', c: '+1', m: '+1(999) 999-9999', id: 't-phonemask_us' },
        uy: {
          n: 'Uruguay',
          c: '+598',
          m: '+598-9-999-99-99',
          id: 't-phonemask_uy',
        },
        uz: {
          n: 'Uzbekistan (Oʻzbekiston)',
          c: '+998',
          m: '+998-99-999-9999',
          id: 't-phonemask_uz',
        },
        vu: {
          n: 'Vanuatu',
          c: '+678',
          m: '+678-99-99999',
          id: 't-phonemask_vu',
        },
        va: {
          n: 'Vatican City (Città del Vaticano)',
          c: '+39',
          m: '+39-9-999-99999',
          id: 't-phonemask_va',
        },
        vn: {
          n: 'Vietnam (Việt Nam)',
          c: '+84',
          m: '+84-99-9999-999',
          id: 't-phonemask_vn',
        },
        zm: {
          n: 'Zambia',
          c: '+260',
          m: '+260-99-999-9999',
          id: 't-phonemask_zm',
        },
        other: {
          n: this.$t('phone.other'),
          c: '+',
          m: false,
          id: 't-phonemask_other',
        },
      },
    };
  },
  computed: {
    formValue: {
      set: function (value) {
        this.debug({ name: 'comp formValue set' }, 'arg value = %O', value);
        if (String(value)) {
          const _value = `${this.phoneMaskObj.c}${value}`;
          this.debug({ name: 'comp formValue set' }, 'emit input = %O', _value);
          this.$emit('input', _value);
        } else {
          const _value = ``;
          this.debug({ name: 'comp formValue set' }, 'emit input = %O', _value);
          this.$emit('input', _value);
        }
      },
      get: function () {
        if (!String(this.value) || !this.value) {
          const _value = '';
          this.debug({ name: 'comp formValue get' }, '%O', _value);
          return _value;
        }
        const phoneMaskCode = this.phoneMaskObj.c;
        const phone = this.value.substr(phoneMaskCode.length);
        this.debug({ name: 'comp formValue get' }, '%O', phone);
        return phone;
      },
    },
    phoneMaskObj() {
      const phoneMask = this.phoneMaskName;
      const fullPhoneMask = this.PHONE_MASK_COUNTRIES_LIST[phoneMask];
      this.debug({ name: 'comp phoneMaskObj' }, '%O', fullPhoneMask);
      return fullPhoneMask;
    },
    phoneMaskWithoutCode() {
      const phoneMask = this.phoneMaskObj.m;
      const phoneMaskCode = this.phoneMaskObj.c;

      if (!phoneMask) {
        const _value = ''.padStart(22, '9');
        this.debug({ name: 'comp phoneMaskWithoutCode' }, '%O', _value);
        return _value;
      }
      let computedPhoneMask = phoneMask.substr(phoneMaskCode.length);
      if ('-' == computedPhoneMask.charAt(0)) {
        computedPhoneMask = computedPhoneMask.substring(1);
      }

      this.debug(
        { name: 'comp phoneMaskWithoutCode' },
        '%O',
        computedPhoneMask,
      );
      return computedPhoneMask;
    },
    maxLength() {
      const phoneMaskWithoutCode = this.phoneMaskWithoutCode;
      const _value = this.phoneMaskObj.m ? phoneMaskWithoutCode.length : 0;
      this.debug({ name: 'comp maxLength' }, '%O', _value);
      return _value;
    },
    inputMask() {
      const phoneMaskWithoutCode = this.phoneMaskWithoutCode;
      const _value = phoneMaskWithoutCode.replace(/[0-9]/g, '#');
      this.debug({ name: 'comp inputMask' }, '%O', _value);
      return _value;
    },
  },
  created() {
    this.PHONE_MASK_COUNTRIES_LIST_ARRAY = Object.entries(
      this.PHONE_MASK_COUNTRIES_LIST,
    ).map(([key, value]) => ({ key, ...value }));
  },
  mounted() {
    this.attach = this.$refs['phoneInput'].$el;
    this.$watch(
      () => {
        return this.$refs.phoneInputSelect.isMenuActive;
      },
      async (newValue) => {
        this.isMenuActive = newValue;
        if (newValue === false) {
          await Vue.nextTick();
          this.$refs?.phoneInput?.focus();
        }
      },
    );
  },
  methods: {
    onClickSelectFlags() {
      // Закомментировал, т.к. смысл кода непонянет, а так же визуально появился инпут (см. коммит)
      // e.stopPropagation();
      // this.$refs.phoneSelectInput.focus();
    },
    focus() {
      this.$refs?.phoneInput?.focus();
    },
  },
};
