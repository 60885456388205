export const state = () => ({
  nomenclatures: [],
  newNomenclatures: [],
  blogs: [],
  reviews: [],
  webinars: [],
});

export const mutations = {
  setNomenclatures(state, nomenclatures) {
    state.nomenclatures = nomenclatures;
  },
  setNewNomenclatures(state, newNomenclatures) {
    state.newNomenclatures = newNomenclatures;
  },
  setBlogs(state, blogs) {
    state.blogs = blogs;
  },
  setReviews(state, reviews) {
    state.reviews = reviews;
  },
  setWebinars(state, webinars) {
    state.webinars = webinars;
  },
};
