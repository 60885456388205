//
//
//
//

export default {
  watch: {
    '$store.state.ui.scroll.isLocked'(payload) {
      try {
        if (process.server) return;
        const html = document.documentElement;

        if (payload) {
          html.style.top = `-${html.scrollTop}px`;
          html.style.left = '0';
          html.style.position = 'fixed';
          html.style.overflowY = 'scroll';
          html.style.width = '100%';
          html.style.height = '100%';
        } else {
          const scrollTop = Math.abs(parseInt(html.style.top));

          html.style.removeProperty('height');
          html.style.removeProperty('overflow-y');
          html.style.removeProperty('left');
          html.style.removeProperty('top');
          html.style.removeProperty('width');
          html.style.removeProperty('position');
          html.scrollTop = scrollTop;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
