import { render, staticRenderFns } from "./ModalNotify.vue?vue&type=template&id=ad53a680&scoped=true&"
import script from "./ModalNotify.vue?vue&type=script&lang=js&"
export * from "./ModalNotify.vue?vue&type=script&lang=js&"
import style0 from "./ModalNotify.vue?vue&type=style&index=0&id=ad53a680&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad53a680",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EDialogCard: require('/app/landing_generator/node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/dialogCard/index.vue').default,EDialog: require('/app/landing_generator/node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/dialog/index.vue').default})
