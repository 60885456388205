export const state = () => ({
  data: null,
  buzy: false,
  url: null,
});

export const mutations = {
  set(state, payload) {
    state.data = payload;
  },
  setUrl(state, payload) {
    state.url = payload;
  },
  buzy(state, payload) {
    state.buzy = payload;
  },
};

export const actions = {
  async query({ commit, state }, { cio_client_id, country }) {
    commit('buzy', true);
    try {
      const response = await this.$axios({
        method: 'get',
        url: state.url,
        params: {
          cio_client_id,
          country,
        },
      });
      commit('set', response.data);
    } catch (error) {
      console.log(error);
    } finally {
      commit('buzy', false);
    }
  },
};
