const defaultData = {
  title: '',
  description: '',
  action: '',
  displayDescriptionAsHtml: false,
  callback: null,
  onClose: null,
};

export const state = () => ({
  display: false,
  data: defaultData,
  busy: false,
});

export const mutations = {
  _CLEAR_DATA(state) {
    state.data = defaultData;
  },
  _OPEN(state, payload) {
    state.data = defaultData;
    state.data = payload;
    state.display = true;
  },
  _CLOSE(state) {
    state.display = false;
  },
  /**
   * Устанавливает классы disabled и loading на кнопку в модалке
   * */
  _SET_BUSY(state, payload) {
    state.busy = payload;
  },
};

export const actions = {
  async OPEN({ commit }, payload) {
    commit('_OPEN', payload);
  },
  async CLOSE({ state, commit }) {
    // Если у функции коллбека есть async, то нужно подождать её выполнения
    // на это время у кнопки в модальном окне появится стейт загрузки
    // и она будет задизейблена
    commit('_SET_BUSY', true);
    try {
      const defaultCallback = () => {};
      const defaultOnClose = () => {};

      const callback = state.data.callback || defaultCallback;
      const onClose = state.data.onClose || defaultOnClose;
      await callback?.();
      onClose?.();
    } catch (e) {
      console.error(e);
    }
    commit('_SET_BUSY', false);

    commit('_CLOSE');
  },

  /**
   * Закрыть модалку без вызова callback
   * */
  async CLOSE_FORCE({ state, commit }) {
    try {
      const onClose = state.data.onClose;
      onClose?.();
    } catch (e) {
      console.error(e);
    }

    commit('_CLOSE');
  },
};
