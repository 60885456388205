export default {
  meta: {
    index: {
      title: 'Cursos, especializaciones y diplomados en línea.',
      description:
        'EBAC es la mejor escuela online para estudiar cursos especializados en Diseño y Tecnología de forma rápida.',
      url: 'https://ebac.mx/',
    },
    vacancies: {
      title: 'Trabaja con nosotros — EBAC Online',
      description:
        'EBAC valora el talento creativo y tecnologico a todos los niveles. ¿Quieres unirte a al equipo EBAC? Consulta nuestra bolsa de trabajo aquí.',
      url: 'https://ebac.mx/vacancies',
    },
    careerCenterStudents: {
      title: 'Centro de carreras para alumnos — EBAC Online',
      description:
        'Estamos 100% comprometidos en la trayectoria de nuestros alumnos, tanto a lo largo de sus estudios, como a la hora de ingresar al mercado laboral.',
      url: 'https://ebac.mx/career-center-students',
    },
    careerCenterCompany: {
      title: 'Centro de carreras para empresas — EBAC Online',
      description:
        'EBAC conecta  sin costo tu empresa con los alumnos y facilita la contratación de los mejores profesionales en la industria del Diseño y la Tecnología.',
      url: 'https://ebac.mx/career-center-company',
    },
    aboutUs: {
      title: 'Sobre nosotros — EBAC Online',
      description:
        'La Escuela Británica de Artes Creativas y Tecnología es un centro de enseñanza internacional, que ofrece cursos en línea con profesores expertos.',
      url: 'https://ebac.mx/about-us',
    },
    sale: {
      title: '— EBAC Online',
      url: 'https://ebac.mx/sale/',
    },
    cookiePolicy: {
      title: 'Política de cookies — EBAC Online',
      description: 'Política de cookies de EBAC Online',
      url: 'https://ebac.mx/cookie-policy',
    },
  },
};
