import { redirectToCoursePage } from '@/pages/sale/utils/redirectToCoursePage';

export default function ({ error, store, req, redirect }) {
  if (!store.state.sale.sale) {
    return error({ statusCode: 404, message: 'This page could not be found' });
  }

  // https://ebaconline.atlassian.net/browse/FT-1667
  // Если у распродажи установлен режим eternal, и есть dateTo,
  // то распродажа должна истечь в dataTo игнорируя eternal.
  // Но, если dateTo не установлен, то распродажа будет бесконечной

  // const eternal = store.state?.sale?.sale?.modes?.eternalCounter;
  const dateToRaw = store.state.sale.sale.endDate;
  const dateTo = new Date(store.state.sale.sale.endDate);
  const currentDate = new Date();

  // Вышло ли время распродажи
  let dateToLasted = false;
  if (dateToRaw && dateTo.getTime() < currentDate.getTime()) {
    dateToLasted = true;
  }

  if (dateToLasted) {
    redirect(redirectToCoursePage(store, req));
  }
}
