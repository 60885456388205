//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Message from './components/error/message.vue';
import Links from './components/error/links.vue';

export default {
  components: {
    Message,
    Links,
  },
  layout: 'scaled',
  head() {
    return {
      title: '404',

      bodyAttrs: {
        class: 'body--error',
      },
    };
  },
  created() {
    this.$store.commit('ui/SET_ERROR_PAGE_ACTIVE', true);
  },
  beforeDestroy() {
    this.$store.commit('ui/SET_ERROR_PAGE_ACTIVE', false);
  },
};
