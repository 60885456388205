import { render, staticRenderFns } from "./links.vue?vue&type=template&id=9637f76e&scoped=true&"
import script from "./links.vue?vue&type=script&lang=js&"
export * from "./links.vue?vue&type=script&lang=js&"
import style0 from "./links.vue?vue&type=style&index=0&id=9637f76e&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9637f76e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EBtn: require('/app/landing_generator/node_modules/@ebac-online/ebac-ui-kit/src/designs/publicSite/components/btn/index.vue').default})
