//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrorLinks',
  computed: {
    // Кнопки на 404 странице берутся из массива в конфиге для страны
    // landing_generator/config/countries
    links() {
      return this.$app.config.current.links404 || [];
    },
  },
};
