export default async function ({ app, route, store, $config }) {
  if (route.query.sale) {
    try {
      const response = await app.$axios({
        method: 'GET',
        url: `${$config.CMS_REST_API}/public/v2/sale`,
        params: {
          slug: route.query.sale,
        },
      });

      if (response?.data?.data) {
        store.commit('thankYouSale/setSale', response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
