var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"main-courses-section"},[_c('div',{staticClass:"m-container"},[_c('div',{staticClass:"main-courses-section__left-block"},[_c('h2',{staticClass:"h2"},[_vm._v(_vm._s(_vm.$t(_vm.text_h2)))]),_vm._v(" "),_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t(_vm.text_subtitle)))]),_vm._v(" "),_c('VBtn',{class:[
          'main-courses-section__left-block-button',
          { 'mt-1': _vm.sortNew } ],attrs:{"color":"primary","rounded":"","depressed":"","outlined":"","x-large":"","ripple":false,"nuxt":"","to":"/cursos"}},[_vm._v("\n        "+_vm._s(_vm.$t('index.main-courses-section.btn'))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"main-courses-section__right-block"},[_c('section',{staticClass:"products"},[_c('div',{staticClass:"products__list"},_vm._l((_vm.nomenclatures),function(nomenclature){return _c(nomenclature.comingSoon ? 'div' : 'a',{key:nomenclature.pageUrl,tag:"Component",staticClass:"products__item",style:({
              '--product-color': ("#" + (nomenclature.backgroundColor)),
            }),attrs:{"href":nomenclature.pageUrl},on:{"click":function () { return _vm.$gtm.push({
                  event: 'autoEvent',
                  eventCategory: 'click',
                  eventAction: 'index_courses',
                  eventLabel: nomenclature.name,
                }); }}},[_c('span',{staticClass:"body-s products__item-duration"},[_vm._v(_vm._s(_vm.$tc('month', nomenclature.duration)))]),_vm._v(" "),_c('div',{staticClass:"products__item-wrap"},[(nomenclature.comingSoon)?_c('div',{staticClass:"products__item-badge"},[_vm._v("\n                "+_vm._s(_vm.$t('comingSoon'))+"\n              ")]):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"products__item-title"},[_vm._v(_vm._s(nomenclature.name))]),_vm._v(" "),_c('b',{staticClass:"products__item-category"},[_vm._v(_vm._s(nomenclature.type === 1
                  ? _vm.$t('index.main-courses-section.profissao')
                  : _vm.$t('index.main-courses-section.curso')))])]),_vm._v(" "),_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"products__item-image",attrs:{"data-src":(nomenclature.images.showcaseImage &&
                  nomenclature.images.showcaseImage.url) ||
                ("/images/nomenclatures/" + (nomenclature.slug) + ".svg"),"alt":nomenclature.images.showcaseImage &&
                nomenclature.images.showcaseImage.description,"width":"146","height":"148"}})])}),1),_vm._v(" "),_c('VBtn',{attrs:{"color":"primary","rounded":"","depressed":"","outlined":"","x-large":"","ripple":false,"nuxt":"","to":"/cursos"}},[_vm._v("\n          "+_vm._s(_vm.$t('index.main-courses-section.btn'))+"\n        ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }