//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainFormSection from '@/components/mainPage/mainFormSection';

export default {
  components: {
    MainFormSection,
  },
  layout: 'hiddenHeader',
  head() {
    return {
      title: '404',
    };
  },
};
