import Vue from 'vue';
const debug = require('debug');

const isProd = process.env.NODE_ENV === 'production';
if (isProd) debug.disable();

const IGNORE_PATH = ['VApp', 'Nuxt'];

Vue.mixin({
  ...(process.client && {
    inject: {
      debugPath: {
        from: 'debugPath',
        default: [],
      },
    },
    provide() {
      if (this.debugPath) this._debugPath.push(...this.debugPath);
      if (this.$options._componentTag) {
        if (!IGNORE_PATH.includes(this.$options._componentTag)) {
          this._debugPath.push(this.$options._componentTag);
        }
      } else if (this.$metaInfo && this.getRouteBaseName) {
        this._debugPath.push(this.getRouteBaseName());
      }

      return {
        debugPath: this._debugPath,
      };
    },
  }),
  beforeCreate() {
    this._debugPath = [];
  },
  created() {
    this.debug = (...args) => {
      const _debug = debug(this._debugPath.join(':'));
      if (args[0] && typeof args[0] === 'object') {
        if (args[0].name) {
          if (args.length > 1) {
            _debug(`(${args[0].name}) ${args[1]}`, ...args.slice(2));
          } else {
            _debug(`(${args[0].name})`);
          }
        } else {
          if (args.length > 1) {
            _debug(...args.slice(1));
          } else {
            _debug('');
          }
        }
      } else {
        _debug(...args);
      }
    };
  },
});
