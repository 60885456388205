export const state = () => ({
  directions: [],
});

export const getters = {
  getDirectionIdByBlogSlug: (state) => (blogSlug) => {
    return state.directions.find((i) => i.blogSlug === blogSlug)?.id;
  },
  getDirectionByBlogSlug: (state) => (blogSlug) => {
    return state.directions.find((i) => i.blogSlug === blogSlug);
  },
};

export const mutations = {
  setDirections(state, directions) {
    state.directions = directions;
  },
};
