import message from '../../i18n/es-mx';
import { vwoCodeMX } from '../const';

// Публичный ключ от рекапчи. Подставляется в Head Scripts
// https://www.google.com/recaptcha/admin/
const grecaptchaPublicKey = '6LfR9OopAAAAAPsHO9XuBuXfIKZR4kukqm9mAMoh';

// Мексика
export default {
  grecaptchaPublicKey,

  // Включена ли рекапча для получения score юзера
  grecaptchaEnabled: true,

  // Плашка распродажи
  saleBanner: {
    endDate: '2023-06-12T05:59:00Z', // пример 2022-08-27T05:59:00Z - до 26 августа включительно
    eternal: true, // вечная распродажа? если да, то endDate можно не заполнять (если ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, ПН, то до ПН)
    eternalV2: false, // вечная распродажа? если да, то endDate можно не заполнять (если ПН, ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, то до ВС)
    name: '020823', // название распродажи, формат ddmmyy
    link: 'https://ebac.mx/sale/promociones', // ссылка на распродажу
  },

  // Slug распродажи, которую нужно отобразить в новом дизайне
  saleNewDesignSlug: ['test-sale-1'],

  saleReferral: [],

  // Поведение корзины на странице распродажи
  // Ключ - slug, value - опция
  // 1 - дефолт
  // 2 - дефолт + при добавлении в корзину, popup корзины сразу же будет появляться
  // 3 - как таковой корзины нет, при добавлении в корзину будет появляться popup корзины,
  // но в popup`e будут только форма, списка продуктов не будет. После закрытия popup`а,
  // товар будет удаляться из корзины. Плавающая иконка корзины пропадает
  saleBasketType: {},

  // Slug распродажи, на котором будет отображено колесо фортуны
  fortuneWheelSlug: [],

  // Редиректы на платформу автовебинаров
  autoWebinarsRedirects: [
    {
      fromWebinarSlug: 'uxui-webinar-2024-01-30',
      to: 'https://s14795.accelsite.io/w/1546007',
    },
    {
      fromWebinarSlug: 'product-2024-webinar-02-22',
      to: 'https://s14795.accelsite.io/w/1546007',
    },
    // {
    //   fromWebinarSlug: 'uxui-product-2024-02-29',
    //   to: 'https://s14795.accelsite.io/w/1546007',
    // },
  ],

  // Локализация
  localeName: 'es-mx',
  utcOffset: -6,
  message,
  numberFormat: {
    currency: {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'symbol',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'MXN',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
    },
  },
  defaultPhoneMask: 'mx',

  newDomainName: '', // домен, который будет заменять домен у некоторых ссылок, приходящих из cms. 1g5jk12

  lmsUrl: 'https://lms.ebac.mx/',
  lmsLoginUrl: 'https://lms.ebac.mx/login',

  // Webinars
  withWebinars: true,
  webinarsUrl: '/webinars',
  // Blog
  withBlog: true,
  tildaBlog: false,
  blogUrl: 'https://ebac.mx/blog',
  getBlogsUrl: 'https://ebac.mx/api/tilda-main-blog',
  blogLinks: [
    {
      href: 'https://ebac.mx/blog#!/tab/332850082-2',
      key: 'template.design',
    },
    {
      href: 'https://ebac.mx/blog#!/tab/332850082-3',
      key: 'template.gaming',
    },
  ],

  withNewCoursesSection: false,

  feedbackUrl:
    'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/mx/subscribe/register',
  feedbackContentType: 'application/x-www-form-urlencoded',
  leadFormUrl:
    'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/queue/mx/subscribe/course',
  saleUrl:
    'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/queue/mx/subscribe/course',
  promocodeUrl: 'https://cms.ebac.mx/api/shopping-cart',
  wordPressPosts: 'https://ebac.mx/blog/wp-json/wp/v2/posts',
  webinarsCreateLeadUrl:
    'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/mx/subscribe/webinar',
  webinarsCreateLeadUrlAws:
    'https://d9th96quea.execute-api.sa-east-1.amazonaws.com/Prod/mx/subscribe/webinar',
  webinarsCreateLeadContentType: 'application/json',
  getPaymentLink: 'https://ebac.mx/api/subscriptions/getPaymentLink',
  getDetailWithPaymentLink: 'https://billing.ebac.app/offer/detail-with-links',
  checkSubscriptionsLink: 'https://api.lms.ebac.mx/subscriptions/is-mine',
  webinarVisitorEventsUrl:
    'https://wisggajuuczifmvdtlzwluwpei0urtta.lambda-url.sa-east-1.on.aws/webinar-visitors-webhook',
  showcaseApiUrl: 'https://cms.ebac.mx/api/public/v2/showcase',

  // Source id
  sourceId: {
    index: 10003,
    sale: 11000,
    saleBasket: 11000,
  },

  // Documents
  documents: {
    privacyNoticeStudents:
      'https://ebac.mx/upload/cms/ebac_aviso_de_privacidad_integral_alumnos.pdf',
    privacyNoticeTeachers:
      'https://ebac.mx/upload/cms/ebac_aviso_de_privacidad_integral_profesores.pdf',
    termsAndConditions:
      'https://ebac.mx/upload/cms/ebac_tyc_alumnos_pag_cursos.pdf',
    cursos: 'https://ebaconline.com.br/cursos',
  },
  footerDocuments: [
    'privacyNoticeStudents',
    'privacyNoticeTeachers',
    'termsAndConditions',
    'cursos',
  ],
  formDocument: 'privacyNoticeStudents', // documents.privacyNoticeStudents
  formWithCheckbox: true,

  // Social
  phone: '+52 55 9225-2629',
  phoneLink: 'tel:+525592252629',
  whatsApp: '+52 55 9225-2629',
  whatsAppLink: 'https://wa.me/525592252629',
  email: '',
  emailLink: '',
  instagramLink: 'https://www.instagram.com/ebac_latam',
  facebookLink: 'https://www.facebook.com/EbacLATAM',
  youtubeLink: 'https://www.youtube.com/channel/UCVqYXk6kbrdcDbmPtOw9v4g',
  linkedInLink: 'https://www.linkedin.com/company/ebaclatam',
  tiktok: 'https://www.tiktok.com/@ebac_latam',

  // Vacancies
  vacancies: {
    locations: [
      {
        slug: 'mexico-city',
        value: 'São Paulo',
        label: 'Ciudad de México',
      },
      {
        slug: 'remote',
        value: 'Remote',
        label: 'Remote',
      },
    ],
  },
  externalVacanciesLink: 'https://ebac_latam.gupy.io/',

  //b2b landing link
  b2bLinks: {
    corporateEducation: {
      url: 'https://ebac.mx/para-empresas/educacion-corporativa',
    },
    challenges: { url: 'https://ebac.mx/para-empresas/atraccion-de-talento' },
  },

  // Global head
  head: {
    lang: 'es',
    meta: [
      {
        name: 'facebook-domain-verification',
        content: 'xxovr0wpum73urzqw7s1xn4rzw3gi2',
      },
      {
        name: 'google-site-verification',
        content: 'yzzTXNX8OXD7QrGXUwIRewJQdvFo53rDEAiKmqGOa_4',
      },
    ],
    link: [
      // Преконнект сервиса a/b тестов
      {
        rel: 'preconnect',
        href: 'https://dev.visualwebsiteoptimizer.com',
      },
    ],
    script: [
      // {
      //   innerHTML: `(function(a,m,o,c,r,m){a[m]={id:"55739",hash:"1ff7300d2677990955830635bd87352e4d8646284065bebd0d2b4738baf4a888",locale:"en",inline:true,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.com/js/button.js?1630666958';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));`,
      //   type: 'text/javascript',
      //   async: true,
      // },

      {
        src: 'https://cdn.jsdelivr.net/npm/vue@2.6.14',
        body: true,
        defer: true,
      },
      {
        src: 'https://widgets.ebac.app/cookie-consent/mx/cookie-consent-init.js',
        body: true,
        defer: true,
      },
      {
        src: 'https://widgets.ebac.app/cookie-consent/mx/cookie-consent.min.js',
        body: true,
        defer: true,
      },

      // Подключение сервиса a/b тестов
      {
        type: 'text/javascript',
        id: 'vwoCode',
        innerHTML: vwoCodeMX,
      },

      // Подключение Google Recaptcha v3
      {
        src: `https://www.google.com/recaptcha/api.js?render=${grecaptchaPublicKey}`,
        body: true,
        defer: true,
      },
    ],
    __dangerouslyDisableSanitizers: ['script'],
  },

  bodyScripts: [],

  // career-center-students script
  careerCenterStudentsScripts: [],

  gtm: {
    debug: false,
    id: 'GTM-TQR2Q6B',
    autoInit: true,
    enabled: true,
    pageTracking: true,
    scriptDefer: true,
  },

  //hreflang linking SEO
  hreflang: 'es-MX',
  href: 'https://ebac.mx',
  code: 'mx',

  autoWebinarLoginLinkAws:
    'https://external-api.ebac.app/axl-autowebinar-subscription-processing',

  additionalText404: false,
  links404: [
    {
      key: 'courses',
      link: '/cursos?types=course',
    },
    {
      key: 'professions',
      link: '/cursos?types=profession',
    },
    {
      key: 'jobGuarantee',
      link: '/cursos?jobGuarantee=true',
    },
    {
      key: 'upcomingEvents',
      link: '/webinars',
    },
    {
      key: 'blogArticles',
      link: 'https://ebac.mx/blog',
    },
    {
      key: 'aboutUs',
      link: '/about-us',
    },
  ],

  lmsRestApiUrl: 'https://api.lms.ebac.mx',

  domain: 'ebac.mx',

  jobGuarantee: true,
};
