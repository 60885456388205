//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoScroll from '@/components/common/noScroll.vue';
import ModalNotify from '@/components/ModalNotify.vue';
import SaleBannerV2 from '../components/saleBannerV2';
import isMobile from '../mixins/isMobile.mixin';
import setDefaultCookie from '../mixins/setDefaultCookie.mixin';
import PortalDialogTarget from '@/components/portalDialogTarget.vue';
import viewportMixin from '@/mixins/viewport.mixin';
import cacheTimeQuery from '@/mixins/cacheTimeQuery';

export default {
  components: {
    SaleBannerV2,
    PortalDialogTarget,
    ModalNotify,
    NoScroll,
  },
  mixins: [isMobile, setDefaultCookie, viewportMixin, cacheTimeQuery],
  data() {
    return {
      layout: undefined,
      observer: undefined,
      saleBannerExcludeRoutes: [
        `webinars-direction___${this.$app.config.current.localeName}`,
        `webinarDetailed___${this.$app.config.current.localeName}`,
        `sale___${this.$app.config.current.localeName}`,
        `sale-sale___${this.$app.config.current.localeName}`,
        `sale-thankyou___${this.$app.config.current.localeName}`,
        `webinarSale___${this.$app.config.current.localeName}`,
        `webinarSaleNew___${this.$app.config.current.localeName}`,
        `webinars-slug___${this.$app.config.current.localeName}`,
        `payment-generate___${this.$app.config.current.localeName}`,
        `payment-fail___${this.$app.config.current.localeName}`,
        `payment-success___${this.$app.config.current.localeName}`,
      ],
      saleBannerIsExternal:
        this.$app.config.current.saleBanner.eternalV2 ||
        this.$app.config.current.saleBanner.eternal,
      saleBannerTopPositionRoutes: [
        `webinars-slug___${this.$app.config.current.localeName}`,
      ],
      isShowSaleBanner: false,
    };
  },
  head() {
    const links = [];

    const canonical = [];
    canonical.push('https://');
    canonical.push(this.$app.config.current.domain);
    if (this.$route.path !== '/') canonical.push(this.$route.path);
    const canonicalUrl = canonical.join('');

    links.push({
      rel: 'canonical',
      href: canonicalUrl,
    });

    return {
      link: links,
    };
  },
  computed: {
    isEmbeddedBlocksHidden() {
      return this.$store.state.ui.isEmbeddedBlocksHidden;
    },
    snackbarParams() {
      let position = 'top-left';
      let offsetY = 30;
      let offsetX = 30;

      // На всех скейлед страницах плавающий хедер
      // На телефонах нужно опускать снекбар ниже
      const isMobile = this.isMobile;
      const isTablet = this.isTablet;
      if (isMobile) {
        offsetY = 90;
        offsetX = 16;
      }

      // На странице распродажи другой хедер.
      // На десктопе снекбар будет слева-снизу
      // на планшетах - сверху
      const routeName = this.$route.name;
      const isSalePage = routeName?.includes('sale-sale___');

      if (isSalePage) {
        offsetY = 12;
        position = 'bottom-left';

        if (isMobile) {
          offsetY = 12;
          position = 'top-left';
        }
      }

      return {
        position,
        offsetY,
        offsetX,
      };
    },
    errorPageActive() {
      return this.$store.state.ui.errorPageActive;
    },
  },
  watch: {
    $route: {
      handler() {
        this.checkIsSaleBannerVisible();
      },
      immediate: true,
    },
    errorPageActive: {
      handler() {
        this.checkIsSaleBannerVisible();
      },
      immediate: true,
    },
  },
  methods: {
    checkIsSaleBannerVisible() {
      if (process.client) {
        window.dispatchEvent(new Event('recalculateCookieConsent'));
      }

      // Страница 404
      if (this.errorPageActive) {
        this.isShowSaleBanner = false;
        return;
      }

      // Обработка роутов исключений
      if (!this.saleBannerExcludeRoutes.includes(this.$route.name)) {
        this.isShowSaleBanner = true;
      } else this.isShowSaleBanner = false;
    },
  },
};
