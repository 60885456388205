const defaultErrorText = 'snackbar.error.default';

export default function (e) {
  const snackbar = this.$snackbar;

  // Проверяем, что снекбар и его методы установлены в экземпляре Vue
  if (snackbar?.add) {
    const title = this.$t(matchErrorText(e));

    snackbar.add({ title });
  }

  // Для трека ошибки в Sentry
  console.error(e);
}

function matchErrorText(error) {
  const message = error?.message;
  if (message) return defaultErrorText;

  // Пример обработки ошибки - нигде не используется на самом деле
  // идея в том, чтобы иметь возможность парсить ошибки с сервера
  // которые могут приходить в свободной форме
  // и заменять их на локализованные строки с i18n
  if (textIsMatch(message, 'already exists')) {
    return 'snackbar.error.exists';
  }

  return defaultErrorText;
}

// Проверяет совпадение части текста в ошибке
function textIsMatch(text, pattern) {
  return text.toLowerCase().trim().includes(pattern.toLowerCase().trim());
}
