export const state = () => ({
  cursosList: {},
  eventList: {},
  saleList: {},
  newSale: {},
});

export const mutations = {
  SAVE(state, { namespace, filter, fields }) {
    state[namespace] = {
      filter,
      fields,
      clearable: false,
      selectedCount: 0,
      freeze: false,
      freezedState: {},
      queryKey: 1,
    };
  },
  UPDATE(state, { namespace, payload }) {
    state[namespace].filter = payload;
  },
  UPDATE_FIELD(state, { namespace, fieldName, payload }) {
    state[namespace].filter[fieldName] = payload;
  },
  SET_CLEARABLE_AND_COUNT(state, { namespace, count }) {
    state[namespace].clearable = count > 0;
    state[namespace].selectedCount = count;
  },
  SET_FREEZE(state, { namespace, payload, ignore }) {
    try {
      state[namespace].freeze = payload;

      if (payload) {
        // Заморозить фильтр. Текущее состояние сохраняется в отдельной переменной стора
        const currentState = JSON.parse(
          JSON.stringify(state[namespace].filter),
        );
        state[namespace].freezedState = currentState;
        return;
      }

      // Разморозка фильтра
      if (!payload) {
        // Последние изменения фильтра игнорируются (например: модальное окно закрыто без сохранения)
        // Фильтр откатывается к состоянию на момент заморозки
        if (ignore) {
          const freezedState = JSON.parse(
            JSON.stringify(state[namespace].freezedState),
          );

          state[namespace].filter = freezedState;
          state[namespace].freezedState = {};
          return;
        }

        // Последние изменения фильтра НЕ игнорируются. Фильтр применён
        if (!ignore) {
          state[namespace].freezedState = {};
          return;
        }
      }
    } catch (e) {
      console.error(e);
    }
  },
  UPDATE_QUERY_KEY(state, { namespace }) {
    state[namespace].queryKey = Math.random();
  },
};

export const actions = {
  SAVE({ commit }, { namespace, filter, fields }) {
    commit('SAVE', { namespace, filter, fields });
  },
  UPDATE({ commit }, { namespace, payload }) {
    commit('UPDATE', { namespace, payload });
    commit('UPDATE_QUERY_KEY', { namespace });
  },
  UPDATE_FIELD({ commit }, { namespace, fieldName, payload }) {
    commit('UPDATE_FIELD', { namespace, fieldName, payload });
    commit('UPDATE_QUERY_KEY', { namespace });
  },
};
