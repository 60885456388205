/**
 * Если в адресной строке есть query параметр cache_time, то
 * его значение заменится на Date.now().
 * Это нужно для сброса cloudFront кеша.
 * */
export default {
  created() {
    if (process.client) {
      const queryCacheTime = this.$route.query.cache_time;

      if (queryCacheTime) {
        const currentRoute = this.$route;
        const query = { ...currentRoute.query };

        query.cache_time = String(Date.now());

        this.$router.replace({ query }).catch(() => {});
      }
    }
  },
};
