export default function ({ route: { meta }, store }) {
  let trigger = false;

  meta.forEach((metaItem) => {
    if (Object.prototype.hasOwnProperty.call(metaItem, 'isLandingPage')) {
      trigger = true;
      store.commit('setIsLandingPage', !!metaItem.isLandingPage);
    }
  });

  if (!trigger) store.commit('setIsLandingPage', false);
}
