//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EButton from '@/components/d0822/fields/button.vue';
import viewportMixin from '@/mixins/viewport.mixin';

export default {
  components: {
    EButton,
  },
  mixins: [viewportMixin],
  computed: {
    display() {
      return this.$store.state.modalNotify.display;
    },
    data() {
      return this.$store.state.modalNotify.data;
    },
    busy() {
      return this.$store.state.modalNotify.busy;
    },
  },
  methods: {
    closeAndCallback() {
      this.$store.dispatch('modalNotify/CLOSE');
    },
    close() {
      this.$store.dispatch('modalNotify/CLOSE_FORCE');
    },
  },
};
