//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    fill: Boolean,
    fillReverse: Boolean,
    outline: Boolean,
    outlineBlackCover: Boolean,
    black: Boolean,
    primary: Boolean,
    round: Boolean,
    gray: Boolean,
    thin: Boolean,

    disabled: Boolean,
    loading: Boolean,
  },
};
