export default async function ({ app, route, error, redirect, $config, req }) {
  const slug = route.params.slug;
  const query = route.query;
  const slugIsNumber = !isNaN(Number(slug));

  const goTo404Page = () => {
    return error({ statusCode: 404, message: 'This page could not be found' });
  };

  if (slugIsNumber) {
    const id = Number(slug);

    try {
      const response = await app.$axios({
        method: 'GET',
        url: `${$config.CMS_REST_API}/public/v2/showcase`,
        params: {
          id,
        },
      });

      if (response?.data?.data?.data) {
        const nomenclatures = response.data.data.data;
        if (nomenclatures.length) {
          const nomenclature = nomenclatures[0];
          let refererString = '';

          try {
            if (req.headers.referer) {
              const refererUrl = new URL(req.headers.referer);
              refererString = `${refererUrl.origin}${refererUrl.pathname}`;
            }
          } catch (error) {
            console.error(error);
          }

          const url = [nomenclature.pageUrl];
          const queryArray = Object.entries(query).map(
            ([key, value]) => `${key}=${value}`,
          );
          if (refererString) queryArray.push(`referer=${refererString}`);
          const queryString = queryArray.join('&');
          if (queryString) {
            url.push('?');
            url.push(queryString);
          }

          return redirect(url.join(''));
        }
      }

      return goTo404Page();
    } catch (error) {
      console.error(`error`, error);
      return goTo404Page();
    }
  } else {
    return goTo404Page();
  }
}
