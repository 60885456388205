export const state = () => ({
  directions: [],
  moreInfo: true,
  displayChat: true,
  selectedTopic: {},
  selectedTopicIndex: 0,
  topicsClient: [],
  registerDialog: false,
  registerDialogSilent: false,
  preRegisterDialog: false,
  subscriptions: null,
  currentSubscribed: null,
  forcelive: false,

  abTestPrefix: '-event',
  abTestPrefixActive: false,
});

export const mutations = {
  setDirections(state, directions) {
    state.directions = directions;
  },
  setMoreInfo(state, status) {
    state.moreInfo = status;
  },
  setDisplayChat(state, status) {
    state.displayChat = status;
  },
  setSelectedTopic(state, { selectedTopic, selectedTopicIndex }) {
    state.selectedTopic = selectedTopic;
    state.selectedTopicIndex = selectedTopicIndex;
  },
  setTopicsClient(state, topics) {
    state.topicsClient = topics;
  },
  setRegisterDialog(state, value) {
    state.registerDialog = value;
  },
  setRegisterDialogSilent(state, value) {
    state.registerDialogSilent = value;
  },
  setPreRegisterDialog(state, value) {
    state.preRegisterDialog = value;
  },
  setSubscriptions(state, value) {
    state.subscriptions = value;
  },
  setCurrentSubscribed(state, value) {
    state.currentSubscribed = value;
  },
  setForcelive(state, value) {
    state.forcelive = value;
  },
  setAbTestPrefixActive(state, value) {
    state.abTestPrefixActive = value;
  },
};

export const actions = {
  openRegisterDialog({ commit, state }, { user, webinar }) {
    if (user) {
      const showPhoneFieldOnForm =
        webinar.showPhoneField || state.abTestPrefixActive;
      const userHasPhone =
        user.profile && user.profile.phone && user.profile.phone !== 'null';

      if (!showPhoneFieldOnForm || (showPhoneFieldOnForm && !!userHasPhone)) {
        commit('setRegisterDialogSilent', true);
      }
    }

    commit('setRegisterDialog', true);
  },
};
