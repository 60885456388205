import { extend, setInteractionMode } from 'vee-validate';
import { required, min, email } from 'vee-validate/dist/rules';

const EMAILS_BLACK_LIST = [
  '@gmaiil.com',
  '@hotnail.com',
  '@gmajl.com',
  '@hitnail.com',
  '@gaiil.com',
  '@gm.com',
  '@gmail.comeh',
  '@gomail.com',
  '@gamel.com',
  '@gmailgmail.com',
  '@gmail.comk',
  '@gmail.com.comm.com',
  '@htomail.com',
  '@hotemail.com',
  '@yahoo.combr',
  '@gimal.com',
  '@gmail.con',
  '@gmail.comer',
  '@gamil.com',
  '@gmali.com',
  '@gmail.comde',
  '@emaio.com',
  '@gamai.com',
  '@gtmail.com',
  '@gmail.como',
  '@gmial.com',
  '@gmail.com.brlo',
  '@gmaoi.com',
  '@gmail.co.com',
  '@gmil.com',
  '@gmail.vom',
  '@gmail.cim',
  '@g.mail.com',
  '@gmlia.com',
  '@gm.com',
  '@gnail.com',
  '@hotmail.con',
  '@gma.com',
  '@gamel.com',
  '@gmail.come',
  '@gmailmail.com',
  '@gmail.xom',
  '@mail.vom',
  '@gmailmail.com',
  '@gmail.combr',
  '@gmail.comde',
  '@gmail.col',
  '@gmail.om',
  '@gmail.comm',
  '@gmal.com',
  '@gmai.com',
  '@gmeil.com',
  '@gmaio.com',
  '@hormail.com',
  '@gamail.com',
  '@hotmil.com',
  '@gmaail.com',
  '@gmeail.com',
  '@gmaill.com',
  '@gmail.cm',
  '@hitmail.com',
  '@hitmail.com',
  '@gmail.br',
  '@gmaul.com',
  '@agmail.com',
  '@gmail.com.br',
];

export default async ({ app }) => {
  extend('required', {
    ...required,
    message: app.i18n.t('required'),
  });
  extend('min', {
    ...min,
    message: app.i18n.t('min'),
  });
  extend('email', {
    ...email,
    message: app.i18n.t('email'),
  });
  extend('emailInBlackList', {
    validate: (value) =>
      !EMAILS_BLACK_LIST.some((substring) => value.endsWith(substring)),
  });

  setInteractionMode('custom', ({ flags }) => {
    if (flags.validated) {
      return {
        on: ['input', 'change'],
      };
    }

    return {};
  });
};
