import hash from '@/utils/hash';

export default function getNomenclatureImage({ vm, product }) {
  // Получить изображение, загруженное для курса в CMS
  const cmsImage = getCmsImage({ product });
  if (cmsImage) return cmsImage;

  // Получить изображение, назначенное для курса в конфиге страны
  const uniqueImage = getUniqueImage({ vm, product });
  if (uniqueImage) return uniqueImage;

  // Получить изображение, назначенное курсу по хэшу из slug
  const randomImage = getRandomImage({ product });
  if (randomImage) return randomImage;

  return '';
}

function getRandomImage({ product }) {
  try {
    const length = 9;
    const title = product.shortName || product.name;
    const index = hash(title, length) + 1;
    const name = `${index}.jpg`;

    return require(`@/assets/images/saleNew/random/${name}`);
  } catch (e) {
    console.error(e);
  }
}

function getUniqueImage({ vm, product }) {
  try {
    const images = vm.$app.config.current.saleNewImages;
    if (!images.length) return '';

    const image = images.find((i) => i.slug === product.slug);
    if (!image) return '';

    return require(`@/assets/images/saleNew/unique/${image.name}`);
  } catch (e) {
    console.error(e);
  }
}

function getCmsImage({ product }) {
  const saleImage = product?.images?.saleImage?.url;
  return saleImage;
}
