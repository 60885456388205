import VueI18n from 'vue-i18n';

VueI18n.prototype.getChoiceIndex = function (n, choicesLength) {
  // declensions of numbers for mx
  // {count}er día | {count}do día | {count}to día | {count}mo día | {count}vo día | {count}no día | {count}o día,
  if (this.locale === 'es-mx') {
    if (choicesLength > 4) {
      return n.toString().endsWith('1') || n.toString().endsWith('3')
        ? 0
        : n.toString().endsWith('2')
        ? 1
        : n.toString().endsWith('4') ||
          n.toString().endsWith('5') ||
          n.toString().endsWith('6')
        ? 2
        : n.toString().endsWith('7') || n % 10 === 0
        ? 3
        : n.toString().endsWith('8')
        ? 4
        : n.toString().endsWith('9')
        ? 5
        : 6; //reserve rule
    }
    if (choicesLength > 2) {
      return n === 0 ? 0 : n === 1 ? 1 : 2;
    }
    return n === 0 ? 0 : n === 1 ? 0 : 1;
  }
  if (choicesLength > 2) {
    return n === 0 ? 0 : n === 1 ? 1 : 2;
  }
  return n === 0 ? 0 : n === 1 ? 0 : 1;
};
