import scrollModule from './scroll';

export const modules = {
  scrollModule,
};

export const state = () => ({
  isEmbeddedBlocksHidden: false,
  errorPageActive: false,
});

export const mutations = {
  SET_IS_EMBEDDED_BLOCKS_HIDDEN(state, payload) {
    state.isEmbeddedBlocksHidden = payload;
  },
  SET_ERROR_PAGE_ACTIVE(state, payload) {
    state.errorPageActive = payload;
  },
};

export const actions = {};
