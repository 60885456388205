import { render, staticRenderFns } from "./portalDialogTarget.vue?vue&type=template&id=1724e65b&scoped=true&"
import script from "./portalDialogTarget.vue?vue&type=script&lang=js&"
export * from "./portalDialogTarget.vue?vue&type=script&lang=js&"
import style0 from "./portalDialogTarget.vue?vue&type=style&index=0&id=1724e65b&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1724e65b",
  null
  
)

export default component.exports