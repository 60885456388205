export default {
  // Публичный ключ от рекапчи. Подставляется в Head Scripts
  // https://www.google.com/recaptcha/admin/
  grecaptchaPublicKey: '',

  // Включена ли рекапча для получения score юзера
  grecaptchaEnabled: false,

  // Плашка распродажи
  saleBanner: {
    endDate: '', // пример 2022-08-27T02:59:00Z - до 26 августа включительно
    eternal: false, // вечная распродажа? если да, то endDate можно не заполнять (если ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, ПН, то до ПН)
    eternalV2: false, // вечная распродажа? если да, то endDate можно не заполнять (если ПН, ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, то до ВС)
    name: '', // "110923" название распродажи, формат ddmmyy
    link: '', // ссылка на распродажу
  },

  // Slug распродажи, которую нужно отобразить в новом дизайне
  saleNewDesignSlug: [],

  saleReferral: [],

  // Поведение корзины на странице распродажи
  // Ключ - slug, value - опция
  // 1 - дефолт
  // 2 - дефолт + при добавлении в корзину, popup корзины сразу же будет появляться
  // 3 - как таковой корзины нет, при добавлении в корзину будет появляться popup корзины,
  // но в popup`e будут только форма, списка продуктов не будет. После закрытия popup`а,
  // товар будет удаляться из корзины. Плавающая иконка корзины пропадает
  // Пример 'garantia-de-futuro': 2,
  saleBasketType: {},

  // Images берутся из assets/images/saleNew/unique/<file>
  saleNewImages: [],

  // Slug распродажи, на котором будет отображено колесо фортуны
  fortuneWheelSlug: [],

  // Редиректы на платформу автовебинаров
  autoWebinarsRedirects: [],

  // Локализация
  localeName: 'pt-br',
  utcOffset: -3,
  message: {},
  numberFormat: {
    currency: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
    },
  },
  defaultPhoneMask: 'bm',

  newDomainName: '', // домен, который будет заменять домен у некоторых ссылок, приходящих из cms. 1g5jk12

  // LMS
  lmsUrl: '',
  lmsLoginUrl: '',

  // Webinars
  withWebinars: false,
  webinarsUrl: '',
  // Blog
  withBlog: false,
  tildaBlog: false,
  blogUrl: '',
  getBlogsUrl: '',
  blogLinks: [],

  withNewCoursesSection: false,

  feedbackUrl: '',
  feedbackContentType: '',
  leadFormUrl: '',
  saleUrl: '',
  promocodeUrl: '',
  wordPressPosts: '',
  webinarsCreateLeadUrl: '',
  webinarsCreateLeadUrlAws: '',
  webinarsCreateLeadContentType: '',
  getPaymentLink: '',
  getDetailWithPaymentLink: '',
  webinarVisitorEventsUrl: '',
  showcaseApiUrl: '',
  checkSubscriptionsLink: '',

  // Source id
  sourceId: {
    index: null,
    sale: null,
    saleBasket: null,
  },

  // Documents
  documents: {
    serviceAgreementAndPrivacyPolicy: '',
    linkToMx: {},
  },
  footerDocuments: [],
  formDocument: '', // documents.serviceAgreementAndPrivacyPolicy
  formWithCheckbox: false,

  // Social
  phone: '',
  phoneLink: '',
  whatsApp: '',
  whatsAppLink: '',
  email: '',
  emailLink: '',
  instagramLink: '',
  facebookLink: '',
  youtubeLink: '',
  linkedInLink: '',
  tiktok: '',

  // Vacancies
  vacancies: {
    locations: [],
  },
  externalVacanciesLink: '',
  ourStudentsLink: '',
  massMediaLink: '',
  studentReviewsLink: '',
  EBACInMedia: '',

  //b2b landing link
  b2bLink: '',
  b2bLinks: {
    corporateEducation: { url: '' },
    challenges: { url: '' },
  },

  // Global head
  head: {
    lang: '',
    meta: [],
    link: [],
    script: [],
    __dangerouslyDisableSanitizers: ['script'],
  },

  bodyScripts: [],

  // career-center-students script
  careerCenterStudentsScripts: [],

  gtm: {
    debug: false,
    id: '',
    autoInit: true,
    enabled: true,
    pageTracking: true,
    scriptDefer: true,
  },

  //hreflang linking SEO
  hreflang: '',
  href: '',
  code: '',

  // Список id номенклатур, для которых выводится категория Pós-Graduação
  postGraduateIds: [],

  /**
   * Список распродаж, для которых автоматически активируется промокод
   * Если readonly: true - активированный купон нельзя стереть
   * или изменить
   *
   * Пример:
   * {
   *  saleSlug: 'junho-da-virada',
   *  code: '230613_dvefp4cf',
   *  readonly: false,
   * },
   * */

  autoFillPromoCodeForSales: [],
  autoWebinarLoginLinkAws: '',

  lmsRestApiUrl: '',

  domain: '',

  additionalText404: false,
  links404: [],

  jobGuarantee: false,
};
