const debug = require('debug')('utils');
const encode = (s) => encodeURIComponent(s).replace(/%20/g, '+');

export default (fd) => {
  let s = '';
  for (let pair of fd.entries()) {
    if (typeof pair[1] == 'string') {
      s += (s ? '&' : '') + encode(pair[0]) + '=' + encode(pair[1]);
    }
  }
  debug('urlencodeFormData retun %O', s);
  return s;
};
