export default function ({ app, route, store, $config }) {
  const slug = route.params.directions;
  if (!slug) return;

  const findedDirection = store.getters['eventsList/GET_DIRECTION_BY_SLUG'](slug);
  if (findedDirection) return;

  const country = $config.COUNTRY;
  let LOCALE;
  switch (country) {
    case 'br':
      LOCALE = 'pt-br';
      break;
    default:
      LOCALE = 'es-mx';
      break;
  }

  const name = `webinars-slug___${LOCALE}`;

  const query = route.query;
  const params = { ...route.params, slug };
  delete params.directions;

  app.router.replace({ name, query, params }).catch(() => {});
}
