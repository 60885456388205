//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate';
import PrivacyPolicyText from './privacyPolicyText.vue';

export default {
  name: 'PrivacyPolicy',
  components: {
    ValidationProvider,
    PrivacyPolicyText,
  },
  props: {
    value: Boolean,
    blackCheckbox: Boolean,
    secondaryCheckbox: Boolean,
    whiteCheckbox: Boolean,
    checkboxSize: {
      type: String,
      validator: (value) => {
        return ['s', 'm', 'l'].includes(value);
      },
      default: 'm',
    },
    checkboxMessageColor: {
      type: String,
      validator: (value) => {
        return ['white', 'red', 'pink'].includes(value);
      },
      default: 'red',
    },
    noCheckbox: Boolean,
    disable: Boolean,
  },
  computed: {
    checked_: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    checkboxTheme() {
      const blackCheckbox = this.blackCheckbox;
      const secondaryCheckbox = this.secondaryCheckbox;
      const whiteCheckbox = this.whiteCheckbox;

      if (blackCheckbox) return 'gray';
      if (secondaryCheckbox) return 'secondary';
      if (whiteCheckbox) return 'white';
      else return 'gray';
    },
  },
};
