//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PaymentSystemItem from './paymentSystemItem';

export default {
  components: {
    PaymentSystemItem,
  },
  props: {
    v: {
      type: String,
      required: true,
      validator: (value) => ['old', 'new'].includes(value),
    },
  },
};
