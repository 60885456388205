export const state = () => ({
  host: '',
  isLandingPage: false,
  loadingPage: 0,
});

export const mutations = {
  SET_HOST(state, payload) {
    state.host = payload;
  },
  setIsLandingPage(state, isLandingPage) {
    state.isLandingPage = isLandingPage;
  },
  SET_LOADING_PAGE_UP(state) {
    state.loadingPage += 1;
  },
  SET_LOADING_PAGE_DOWN(state) {
    state.loadingPage -= 1;
    if (state.loadingPage < 0) state.loadingPage = 0;
  },
};

export const actions = {
  async nuxtServerInit({ commit }, { req }) {
    // console.info(`req.headers.host`, req.headers.host);
    commit('SET_HOST', req.headers.host);
  },
};
