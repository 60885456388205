export const state = () => {
  return {
    vacancies: [],
    locations: [],
    directions: [
      {
        id: 0,
        name: 'vacancies.directions.0',
      },
    ],
  };
};

export const mutations = {
  setVacancies(state, vacancies) {
    state.vacancies = vacancies;
  },
  setLocations(state, locations) {
    state.locations = locations;
  },
  addDirections(state, directions) {
    state.directions = [...state.directions, ...directions];
  },
};
