var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    'button',
    {
      fill: _vm.fill,
      'fill-reverse': _vm.fillReverse,
      outline: _vm.outline,
      outlineBlackCover: _vm.outlineBlackCover,
      black: _vm.black,
      primary: _vm.primary,
      disabled: _vm.disabled,
      round: _vm.round,
      gray: _vm.gray,
      thin: _vm.thin,
    } ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.loading)?[_c('VProgressCircular',{attrs:{"size":25,"indeterminate":"","value":60}})]:[_c('div',{staticClass:"first"},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"second"},[_vm._t("default")],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }