//
//
//
//
//
//
//
//
//
//

import md5 from 'js-md5';

export default {
  name: 'UserAvatar',
  props: ['name', 'onClick'],
  data() {
    return {
      avatarStyle: {
        backgroundColor: this.generateColor(),
      },
    };
  },
  methods: {
    generateColor() {
      return `#${md5(this.name).substr(0, 6)}`;
    },
  },
};
