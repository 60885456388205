export default function ({ route: { meta }, $config, error }) {
  let canContinue = false;

  meta.forEach((metaItem) => {
    if (Object.prototype.hasOwnProperty.call(metaItem, 'show')) {
      if (typeof metaItem.show === 'string' && metaItem.show === 'all') {
        canContinue = true;
      }
      if (
        Array.isArray(metaItem.show) &&
        metaItem.show.includes($config.COUNTRY)
      ) {
        canContinue = true;
      }
    } else if (Object.prototype.hasOwnProperty.call(metaItem, 'doNotShow')) {
      if (
        typeof metaItem.doNotShow === 'string' &&
        metaItem.doNotShow === 'all'
      ) {
        canContinue = false;
      }
      if (
        Array.isArray(metaItem.doNotShow) &&
        metaItem.doNotShow.includes($config.COUNTRY)
      ) {
        canContinue = false;
      } else canContinue = true;
    } else {
      canContinue = true;
    }
  });

  if (!canContinue) {
    return error({ statusCode: 404, message: 'This page could not be found' });
  }
}
