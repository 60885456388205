export const state = () => ({
  link: [],
});

export const mutations = {
  setLink(state, link) {
    state.link = link;
  },
};

export const getters = {
  link: (s) => s.link,
};
