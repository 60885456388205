const debug = require('debug')('utils');

export default (link) => {
  debug('parseYoutubeLinkTold link = %s', link);

  let linkString = String(link || '');
  const ioV = linkString.indexOf('v=');
  if (ioV !== -1) {
    linkString = linkString.slice(ioV + 2);
  } else {
    linkString = /[^/]*$/.exec(linkString)[0];
  }

  let ytId = [];
  for (let i = 0; i < linkString.length; i++) {
    if (linkString[i] !== '&') {
      ytId.push(linkString[i]);
    } else break;
  }

  const result = ytId.join('');

  debug('urlencodeFormData return %s', result);
  return result;
};
