export default function () {
  function getGaCidFromCookie() {
    let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)'),
      raw = match ? decodeURIComponent(match[1]) : null;
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/);
    }
    return match ? match[1] : null;
  }

  function getGaCidFromGa() {
    if (window.ga?.loaded) {
      return window.ga?.getAll()?.[0]?.get('clientId');
    }
  }

  function getGaCidFromGaGlobal() {
    if (window.gaGlobal) {
      return window.gaGlobal.vid;
    }
  }

  try {
    return getGaCidFromCookie() || getGaCidFromGa() || getGaCidFromGaGlobal();
  } catch (error) {
    console.error(error);
  }
}
