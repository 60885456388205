export default async function ({ app, route, store, $config }) {
  if (!store.state.sale.sale) {
    try {
      const response = await app.$axios({
        method: 'GET',
        url: `${$config.CMS_REST_API}/public/v2/sale`,
        params: {
          slug: route.params.sale,
        },
      });

      if (response?.data?.data) {
        store.commit('sale/setSale', response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
