export default {
  state: () => ({
    isLocked: false,
  }),
  getters: {},
  mutations: {
    SET_IS_LOCKED: (state, payload) => {
      state.isLocked = payload;
    },
  },
  actions: {
    lockScroll({ commit }) {
      commit('SET_IS_LOCKED', true);
    },
    unlockScroll({ commit }) {
      commit('SET_IS_LOCKED', false);
    },
  },
};
