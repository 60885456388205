export default ({ name, cookies }) => {
  if (!cookies) return;
  /* eslint-disable no-useless-escape */
  let matches = cookies.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
