export default async function ({ app, /*route, error, store,*/ $config }) {
  let directions = app.store.state.sale.directions || [];
  if (directions.length) return;

  try {
    const response = await app.$axios({
      method: 'GET',
      url: `${$config.CMS_REST_API}/public/v2/directions/`,
      params: {
        saleId: app?.store?.state?.sale?.sale?.id,
      },
    });

    if (response.data.error) {
      throw new Error(
        `An error occurred while executing the query (${response.data.error})`,
      );
    }
    const data = response.data.data;

    directions = data.data;
  } catch (e) {
    console.error(`error`, e);
  }

  app.store.commit(
    'sale/setDirections',
    Object.assign(
      [],
      [
        {
          id: '',
          metaKeywords: '',
          metaDescription:
            'Cursos online com certificados válidos em todo Brasil. Os professores de melhores práticas irão ensinar-lhe todos os segredos das profissões digitais.',
          metaTitle: 'Cursos online com Certificado — EBAC Online',
          seoText:
            'Cursos online com certificados válidos em todo Brasil. Os professores de melhores práticas irão ensinar-lhe todos os segredos das profissões digitais.',
          title: 'Programas de estudo',
          shortName: 'Todas as áreas',
          slug: 'sale',
        },
        ...directions,
      ],
    ),
  );
}
