import * as Sentry from '@sentry/vue';

export const sentryReplaysForUsers = async (user, isSreplay = false) => {
  const client = Sentry.getClient();
  const options = client.getOptions();

  /**
   * Настраиваем общую конфигурацию реплеев
   **/
  const replay = Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  });

  /**
   * Для всех пользователей в 3% случаев
   * пишем реплаи в режиме сессии и
   * отправляем периодически в Sentry
   **/
  options.replaysSessionSampleRate = 0.03;

  /**
   * Если пользователь авторизован
   * или есть query параметр sreplay=true
   * то отправляем в Sentry реплеи
   * при возникновении ошибки
   **/
  if ((user && user.email) || isSreplay) {
    Sentry.setUser({ email: user?.email });
    options.replaysOnErrorSampleRate = 1;

    client.addIntegration(replay);
  } else {
    /**
     * Если пользователь не авторизован
     * или нет query параметра sreplay=true
     * то отправляем в Sentry реплеи
     * при возникновении ошибки в 10% случаев
     **/
    options.replaysOnErrorSampleRate = 0.1;

    client.addIntegration(replay);
  }

  if (!user || !user.email) {
    Sentry.setUser(null);
  }
};
