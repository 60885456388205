export default {
  meta: {
    index: {
      title:
        'Cursos, webinars e treinamento para os profissionais online — EBAC Online',
      description:
        'EBAC — é um jeito rápido de aprender o design e as profissões contemporâneas no Internet. As aulas abertas, os intensivos online e os cursos para uma aprendizagem rápida.',
      url: 'https://ebaconline.com.br/',
    },
    vacancies: {
      title: 'Vagas — EBAC Online',
      description:
        'A EBAC ensina às pessoas as profissões mais modernas e demandadas pelo mercado. Todos nossos esforços são voltados à melhoria da qualidade de ensino e à expansão da abrangência dos nossos cursos.',
      url: 'https://ebaconline.com.br/vacancies',
    },
    careerCenterStudents: {
      title: 'Centro de carreiras para estudantes — EBAC Online',
      description:
        'Estamos 100% focados na trajetória de nossos alunos não apenas ao longo de seus estudos, mas também na hora de ingressar no mercado de trabalho.',
      url: 'https://ebaconline.com.br/career-center-students',
    },
    careerCenterCompany: {
      title: 'Centro de carreiras para empresas — EBAC Online',
      description:
        'A EBAC auxilia sua empresa a expandir a base de candidatos e facilita o recrutamento de profissionais dos mercados criativo e tecnológico gratuitamente.',
      url: 'https://ebaconline.com.br/career-center-company',
    },
    aboutUs: {
      title: 'Sobre nós — EBAC Online',
      description:
        'A Escola Britânica de Artes Criativas & Tecnologia é uma instituição de ensino inovadora que oferece cursos online, além de programas presenciais e híbridos de gradução e especialização.',
      url: 'https://ebaconline.com.br/about-us',
    },
    sale: {
      title: '— EBAC Online',
      url: 'https://ebaconline.com.br/sale/',
    },
    cookiePolicy: {
      title: 'Cookie Policy — EBAC Online',
      description: 'EBAC Online cookie policy',
      url: 'https://ebaconline.com.br/cookie-policy',
    },
  },
};
