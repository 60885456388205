//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightArrow from '../../../landing_generator/assets/svg/right-arrow.svg?inline';
import LeftArrow from '../../../landing_generator/assets/svg/left-arrow.svg?inline';
import Swiper from 'swiper';

export default {
  name: 'ContentList',
  components: {
    RightArrow,
    LeftArrow,
  },
  props: [
    'sliderId',
    'contentClass',
    'isDesktopOneItemSwiper',
    'isDisplayOnDesktop',
    'displayNumber',
    'swiperConfig',
  ],
  data() {
    return {
      swiper: null,
      swiperOneItem: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(`.${this.sliderId}`, {
      slidesPerView: 'auto',
      ...this.$props.swiperConfig,
    });

    if (this.isDesktopOneItemSwiper) {
      this.swiperOneItem = new Swiper(`.${this.sliderId}-one-item`, {
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: `.${this.sliderId}-btn-next`,
          prevEl: `.${this.sliderId}-btn-prev`,
        },
        spaceBetween: 20,
        ...this.$props.swiperConfig,
      });
    }
  },
  methods: {
    oneItemSwiperNextSlide() {
      this.swiperOneItem.slideNext();
    },
    oneItemSwiperPrevSlide() {
      this.swiperOneItem.slidePrev();
    },
    swiperNextSlide() {
      if (
        this.swiper[0].activeIndex + this.displayNumber !==
        this.swiper[0].slides.length
      ) {
        this.swiper?.[0]?.slideNext();
      }
    },
    swiperPrevSlide() {
      this.swiper?.[0]?.slidePrev();
    },
  },
};
