// eslint-disable-next-line no-empty-pattern
export default ({}, inject) => {
  const log = [];

  const push = (message, module) => {
    const date = new Date().toJSON();
    let _message = message;
    if (typeof message != 'string') _message = JSON.stringify(message);
    log.push(`${date}${module ? `(${module})` : ''} - ${_message}`);
  };

  inject('logTrace', {
    log,
    push,
  });
};
