//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViewportMixin from '@/mixins/viewport.mixin';

export default {
  name: 'ErrorMessage',
  mixins: [ViewportMixin],
  data() {
    const additionalText = !!this.$app.config.current.additionalText404;

    return {
      additionalText,
    };
  },
};
