import Vue from 'vue';

// Обработка роутовых переходов внутри приложения для кнопок
function onClick(router, el, e) {
  const href = el?.__vue__?.href;

  if (href) {
    // Проверить, есть ли протокол в начале ссылки
    // Если протокол есть - не применяем директиву
    const isOuterLink = href.startsWith('http');
    if (isOuterLink) return;

    e.preventDefault();
    router.push(href).catch(() => {});
  }
}

export default async ({ app }) => {
  const router = app.router;

  Vue.directive('inner-link', {
    bind(el) {
      el.addEventListener('click', onClick.bind(null, router, el));
    },
    unbind(el) {
      el.removeEventListener('click', onClick.bind(null, router, el));
    },
  });
};
