//
//
//
//

export default {
  name: 'HiddenHeaderLayout',
  components: {
    DefaultLayout: () => import('./default.vue'),
  },
};
