export default {
  beforeMount() {
    this.checkOnMobileSize({ block: window });
  },
  mounted() {
    window.addEventListener('resize', this.checkOnMobileSizeHandler);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkOnMobileSizeHandler);
  },
  methods: {
    checkOnMobileSizeHandler(e) {
      this.checkOnMobileSize({ block: e.target });
    },
    checkOnMobileSize({ block }) {
      this.$app.isMobile = block.innerWidth < this.$app.breakPoint;
    },
  },
};
